import { CountryCode } from '../contracts';

export const locations: { text: string; key: number }[] = [
    { text: 'Afghanistan', key: CountryCode.AF },
    { text: 'Albania', key: CountryCode.AL },
    { text: 'Algeria', key: CountryCode.DZ },
    { text: 'Andorra', key: CountryCode.AD },
    { text: 'Angola', key: CountryCode.AO },
    { text: 'Antarctica', key: CountryCode.AQ },
    { text: 'Argentina', key: CountryCode.AR },
    { text: 'Armenia', key: CountryCode.AM },
    { text: 'Australia', key: CountryCode.AU },
    { text: 'Austria', key: CountryCode.AT },
    { text: 'Azerbaijan', key: CountryCode.AZ },
    { text: 'Bahrain', key: CountryCode.BH },
    { text: 'Bangladesh', key: CountryCode.BD },
    { text: 'Belarus', key: CountryCode.BY },
    { text: 'Belgium', key: CountryCode.BE },
    { text: 'Belize', key: CountryCode.BZ },
    { text: 'Benin', key: CountryCode.BJ },
    { text: 'Bermuda', key: CountryCode.BM },
    { text: 'Bhutan', key: CountryCode.BT },
    { text: 'Bolivia', key: CountryCode.BO },
    { text: 'Bosnia and Herzegovina', key: CountryCode.BA },
    { text: 'Botswana', key: CountryCode.BW },
    { text: 'Brazil', key: CountryCode.BR },
    { text: 'British Indian Ocean Territory', key: CountryCode.IO },
    { text: 'Brunei', key: CountryCode.BN },
    { text: 'Bulgaria', key: CountryCode.BG },
    { text: 'Burkina Faso', key: CountryCode.BF },
    { text: 'Burundi', key: CountryCode.BI },
    { text: 'Cabo Verde', key: CountryCode.CV },
    { text: 'Cambodia', key: CountryCode.KH },
    { text: 'Cameroon', key: CountryCode.CM },
    { text: 'Canada', key: CountryCode.CA },
    { text: 'Central African Republic', key: CountryCode.CF },
    { text: 'Chad', key: CountryCode.TD },
    { text: 'Chile', key: CountryCode.CL },
    { text: 'China', key: CountryCode.CN },
    { text: 'Christmas Island', key: CountryCode.CX },
    { text: 'Cocos (Keeling) Islands', key: CountryCode.CC },
    { text: 'Colombia', key: CountryCode.CO },
    { text: 'Comoros', key: CountryCode.KM },
    { text: 'Congo (DRC)', key: CountryCode.CD },
    { text: 'Congo', key: CountryCode.CG },
    { text: 'Costa Rica', key: CountryCode.CR },
    { text: "Côte d'Ivoire", key: CountryCode.CI },
    { text: 'Croatia', key: CountryCode.HR },
    { text: 'Cyprus', key: CountryCode.CY },
    { text: 'Czech Republic', key: CountryCode.CZ },
    { text: 'Denmark', key: CountryCode.DK },
    { text: 'Djibouti', key: CountryCode.DJ },
    { text: 'Dominican Republic', key: CountryCode.DO },
    { text: 'Ecuador', key: CountryCode.EC },
    { text: 'Egypt', key: CountryCode.EG },
    { text: 'El Salvador', key: CountryCode.SV },
    { text: 'Equatorial Guinea', key: CountryCode.GQ },
    { text: 'Eritrea', key: CountryCode.ER },
    { text: 'Estonia', key: CountryCode.EE },
    { text: 'Ethiopia', key: CountryCode.ET },
    { text: 'Falkland Islands', key: CountryCode.FK },
    { text: 'Finland', key: CountryCode.FI },
    { text: 'France', key: CountryCode.FR },
    { text: 'Gabon', key: CountryCode.GA },
    { text: 'Gambia', key: CountryCode.GM },
    { text: 'Georgia', key: CountryCode.GE },
    { text: 'Germany', key: CountryCode.DE },
    { text: 'Ghana', key: CountryCode.GH },
    { text: 'Gibraltar', key: CountryCode.GI },
    { text: 'Greece', key: CountryCode.GR },
    { text: 'Greenland', key: CountryCode.GL },
    { text: 'Guam', key: CountryCode.GU },
    { text: 'Guatemala', key: CountryCode.GT },
    { text: 'Guinea-Bissau', key: CountryCode.GW },
    { text: 'Guyana', key: CountryCode.GY },
    { text: 'Honduras', key: CountryCode.HN },
    { text: 'Hong Kong SAR', key: CountryCode.HK },
    { text: 'Hungary', key: CountryCode.HU },
    { text: 'Iceland', key: CountryCode.IS },
    { text: 'India', key: CountryCode.IN },
    { text: 'Indonesia', key: CountryCode.ID },
    { text: 'Iran', key: CountryCode.IR },
    { text: 'Iraq', key: CountryCode.IQ },
    { text: 'Ireland', key: CountryCode.IE },
    { text: 'Isle of Man', key: CountryCode.IM },
    { text: 'Israel', key: CountryCode.IL },
    { text: 'Italy', key: CountryCode.IT },
    { text: 'Japan', key: CountryCode.JP },
    { text: 'Jordan', key: CountryCode.JO },
    { text: 'Kazakhstan', key: CountryCode.KZ },
    { text: 'Kenya', key: CountryCode.KE },
    { text: 'Korea', key: CountryCode.KR },
    { text: 'Kosovo', key: CountryCode.XK },
    { text: 'Kuwait', key: CountryCode.KW },
    { text: 'Kyrgyzstan', key: CountryCode.KG },
    { text: 'Laos', key: CountryCode.LA },
    { text: 'Latvia', key: CountryCode.LV },
    { text: 'Lebanon', key: CountryCode.LB },
    { text: 'Lesotho', key: CountryCode.LS },
    { text: 'Liberia', key: CountryCode.LR },
    { text: 'Libya', key: CountryCode.LY },
    { text: 'Liechtenstein', key: CountryCode.LI },
    { text: 'Lithuania', key: CountryCode.LT },
    { text: 'Luxembourg', key: CountryCode.LU },
    { text: 'Macao SAR', key: CountryCode.MO },
    { text: 'Macedonia, FYRO', key: CountryCode.MK },
    { text: 'Madagascar', key: CountryCode.MG },
    { text: 'Malawi', key: CountryCode.MW },
    { text: 'Malaysia', key: CountryCode.MY },
    { text: 'Maldives', key: CountryCode.MV },
    { text: 'Mali', key: CountryCode.ML },
    { text: 'Malta', key: CountryCode.MT },
    { text: 'Mauritania', key: CountryCode.MR },
    { text: 'Mauritius', key: CountryCode.MU },
    { text: 'Mayotte', key: CountryCode.YT },
    { text: 'Mexico', key: CountryCode.MX },
    { text: 'Micronesia Region', key: CountryCode.FM },
    { text: 'Moldova', key: CountryCode.MD },
    { text: 'Monaco', key: CountryCode.MC },
    { text: 'Mongolia', key: CountryCode.MN },
    { text: 'Montenegro', key: CountryCode.ME },
    { text: 'Morocco', key: CountryCode.MA },
    { text: 'Mozambique', key: CountryCode.MZ },
    { text: 'Myanmar', key: CountryCode.MM },
    { text: 'Namibia', key: CountryCode.NA },
    { text: 'Nepal', key: CountryCode.NP },
    { text: 'Netherlands', key: CountryCode.NL },
    { text: 'New Zealand', key: CountryCode.NZ },
    { text: 'Nicaragua', key: CountryCode.NI },
    { text: 'Niger', key: CountryCode.NE },
    { text: 'Nigeria', key: CountryCode.NG },
    { text: 'North Korea', key: CountryCode.KP },
    { text: 'Northern Mariana Islands', key: CountryCode.MP },
    { text: 'Norway', key: CountryCode.NO },
    { text: 'Oman', key: CountryCode.OM },
    { text: 'Pakistan', key: CountryCode.PK },
    { text: 'Palestinian Authority', key: CountryCode.PS },
    { text: 'Panama', key: CountryCode.PA },
    { text: 'Paraguay', key: CountryCode.PY },
    { text: 'Peru', key: CountryCode.PE },
    { text: 'Philippines', key: CountryCode.PH },
    { text: 'Poland', key: CountryCode.PL },
    { text: 'Portugal', key: CountryCode.PT },
    { text: 'Qatar', key: CountryCode.QA },
    { text: 'Reunion', key: CountryCode.RE },
    { text: 'Romania', key: CountryCode.RO },
    { text: 'Russia', key: CountryCode.RU },
    { text: 'Rwanda', key: CountryCode.RW },
    { text: 'Saint Pierre and Miquelon', key: CountryCode.PM },
    { text: 'San Marino', key: CountryCode.SM },
    { text: 'São Tomé and Príncipe', key: CountryCode.ST },
    { text: 'Saudi Arabia', key: CountryCode.SA },
    { text: 'Senegal', key: CountryCode.SN },
    { text: 'Serbia', key: CountryCode.RS },
    { text: 'Seychelles', key: CountryCode.SC },
    { text: 'Sierra Leone', key: CountryCode.SL },
    { text: 'Singapore', key: CountryCode.SG },
    { text: 'Slovakia', key: CountryCode.SK },
    { text: 'Slovenia', key: CountryCode.SI },
    { text: 'Somalia', key: CountryCode.SO },
    { text: 'South Africa', key: CountryCode.ZA },
    { text: 'South Sudan', key: CountryCode.SS },
    { text: 'Spain', key: CountryCode.ES },
    { text: 'Sri Lanka', key: CountryCode.LK },
    { text: 'St Helena, Ascension, Tristan da Cunha', key: CountryCode.SH },
    { text: 'Sudan', key: CountryCode.SD },
    { text: 'Suritext', key: CountryCode.SR },
    { text: 'Swaziland', key: CountryCode.SZ },
    { text: 'Sweden', key: CountryCode.SE },
    { text: 'Switzerland', key: CountryCode.CH },
    { text: 'Syria', key: CountryCode.SY },
    { text: 'Taiwan', key: CountryCode.TW },
    { text: 'Tajikistan', key: CountryCode.TJ },
    { text: 'Tanzania', key: CountryCode.TZ },
    { text: 'Thailand', key: CountryCode.TH },
    { text: 'Timor-Leste', key: CountryCode.TL },
    { text: 'Togo', key: CountryCode.TG },
    { text: 'Tunisia', key: CountryCode.TN },
    { text: 'Türkiye', key: CountryCode.TR },
    { text: 'Turkmenistan', key: CountryCode.TM },
    { text: 'Uganda', key: CountryCode.UG },
    { text: 'Ukraine', key: CountryCode.UA },
    { text: 'United Arab Emirates', key: CountryCode.AE },
    { text: 'United Kingdom', key: CountryCode.GB },
    { text: 'United States', key: CountryCode.US },
    { text: 'Uruguay', key: CountryCode.UY },
    { text: 'Uzbekistan', key: CountryCode.UZ },
    { text: 'Vatican City', key: CountryCode.VA },
    { text: 'Venezuela', key: CountryCode.VE },
    { text: 'Vietnam', key: CountryCode.VN },
    { text: 'Yemen', key: CountryCode.YE },
    { text: 'Zambia', key: CountryCode.ZM },
    { text: 'Zimbabwe', key: CountryCode.ZW }
];

import React, { PropsWithChildren } from 'react';
import { Text } from 'office-ui-fabric-react';

import styles from './Section.module.css';

interface PartialSectionProps {
    ariaLabel?: string;
    title?: React.ReactNode;
    className?: string;
}

export type SectionProps = PropsWithChildren<PartialSectionProps>;

const Section: React.FunctionComponent<SectionProps> = (props: SectionProps): JSX.Element => (
    <section aria-label={props.ariaLabel} className={styles.section}>
        <Text as="h3" className={styles.title}>
            {props.title}
        </Text>
        {props.children}
    </section>
);

export default Section;

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { ActionButton, IPersonaSharedProps, Persona, PersonaSize, PersonaPresence, Spinner, SpinnerSize } from 'office-ui-fabric-react';

import { InterviewFeedbackMetadata } from '../../../environment/interfaces/InterviewFeedbackMetadata';
import { urIVFeedbackViewRoute } from '../../../environment/constants/ur';

import styles from './InterviewFeedbackDashboard.module.css';

const personaProps: IPersonaSharedProps = {};

export interface InterviewFeedbackDashboardProps extends Partial<RouteComponentProps> {
    isLoading: boolean;
    metadata?: InterviewFeedbackMetadata[];
    onGetMetadata?(): Promise<InterviewFeedbackMetadata[]>;
    setIsLoading?(): Promise<boolean>;
}

const InterviewFeedbackDashboard = (props: InterviewFeedbackDashboardProps): JSX.Element => {
    useEffect((): void => {
        if (props.setIsLoading) {
            props.setIsLoading();
        }
        if (props.onGetMetadata) {
            props.onGetMetadata();
        }
        document.title = 'Interview Feedback';
    }, []);

    const onViewFeedbackLinkClick = (application: InterviewFeedbackMetadata): void => {
        if (props.history && application) {
            props.history.push(
                `${urIVFeedbackViewRoute}?jobId=${application.jobId}&applicationId=${application.applicationId}&activityId=${application.activityId}`
            );
        }
    };

    return (
        <>
            {!props.isLoading && (!props.metadata || props.metadata.length === 0) ? (
                <p>Sorry! No Candidates available for feedback</p>
            ) : props.isLoading ? (
                <Spinner className={styles.loginSpinner} size={SpinnerSize.large} label="Loading" />
            ) : (
                <div className={styles.layout}>
                    <table>
                        <thead>
                            <tr className={styles.headerRow}>
                                <th className={styles.columnHeader}>CANDIDATE NAME</th>
                                <th className={styles.columnHeader}>JOB NAME</th>
                                <th className={styles.columnHeader}>JOB ID</th>
                                <th className={styles.columnHeader}>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.metadata &&
                                props.metadata.map(
                                    (application: InterviewFeedbackMetadata): JSX.Element => (
                                        (personaProps.text = application.candidateName),
                                        (
                                            <React.Fragment key={application.applicationId}>
                                                <tr className={styles.nonHeaderRow}>
                                                    <td>
                                                        <span>
                                                            <Persona
                                                                {...personaProps}
                                                                size={PersonaSize.size40}
                                                                presence={PersonaPresence.none}
                                                                hidePersonaDetails={false}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>{application.jobName}</span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {!application.externalJobId ? application.jobId : application.externalJobId}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <ActionButton
                                                            name="viewFeedback"
                                                            className={styles.viewFeedbackLink}
                                                            iconProps={{ iconName: 'Feedback', className: styles.viewFeedbackLink }}
                                                            onClick={(): void => onViewFeedbackLinkClick(application)}
                                                            ariaLabel="view feedback"
                                                            title="View feedback"
                                                        >
                                                            View feedback
                                                        </ActionButton>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )
                                )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default InterviewFeedbackDashboard;

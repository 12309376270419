import React, { PropsWithChildren } from 'react';

interface PartialConditionProps {
    if: ConstrainBoolean;
    then?: JSX.Element;
    else?: JSX.Element;
}

export type ConditionProps = PropsWithChildren<PartialConditionProps>;

export const Condition: React.FunctionComponent<ConditionProps> = (props: ConditionProps): JSX.Element | null => {
    const resolvedComponent = props.if ? props.then ? props.then : <>{props.children}</> : props.else;
    return resolvedComponent || null;
};

export const isProduction = process.env.REACT_APP_ENV_NAME === 'prod';

export const campaignDefinitions = [
    {
        CampaignId: '030b356c-0bd6-4036-bed7-6a0681cb0142', // Assigned by OCV under Discover tab -> surverys
        StartTimeUtc: '2022-04-28T00:00:00Z',
        EndTimeUtc: null,

        // 1: Urgent Channel; 0 - Recommended for Production as it has channel cooldown
        GovernedChannelType: isProduction ? 0 : 1,
        NominationScheme: {
            Type: 0, // Default
            PercentageNumerator: isProduction ? 100 : 100, // Ratio of users to be nominated to be a candidate numerator
            PercentageDenominator: 100, // Ratio of users to be nominated to be a candidate denominator
            NominationPeriod: {
                Type: 0, // Default
                IntervalSeconds: 604800 // NOT used when ratio is 100%
            },
            CooldownPeriod: {
                Type: 0, // Default
                IntervalSeconds: isProduction ? 2592000 : 15 //30 days
            }
        },
        SurveyTemplate: {
            Type: 2, // NPS Template, can be NPS 5pts (0), NPS 11pts (1), FPS (2), NLQs (3), NPS (4), or GenericMessaging Survey (22)
            ActivationEvent: {
                Type: 0, // 0 For one event, 1 for multiple events (array of events)
                Activity: 'SurveyTriggerOnSubmitReferral',
                Count: 1,
                IsAggregate: true
            },
            Content: {
                Prompt: {
                    Title: "We'd love your feedback on referral submission",
                    Question: 'We have two questions for you.',
                    YesLabel: 'Yes',
                    NoLabel: 'No'
                },
                Rating: {
                    IsZeroBased: false,
                    Question: 'How satisfied are you with your experience referring a candidate?',
                    RatingValuesAscending: [
                        'Highly dissatisfied',
                        'Dissatisfied',
                        'Neither satisfied nor dissatisfied',
                        'Satisfied',
                        'Highly satisfied'
                    ]
                },
                Question: { Question: "We'd like to understand more. Please tell us why you gave this satisfaction rating." }
            }
        }
    }
];

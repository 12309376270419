import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { TimedTelemetryEvent } from '@gta/core';

import * as ActionTypes from './actionTypes';
import { HiringTeamMember } from '../../../../environment/interfaces/HiringTeamMember';
import { config, services } from '../../../../environment';
import { executeWithTelemetry } from '../../../../environment/services/telemetry';
import { DynamcisInterviewFeedback } from '../../../../environment/interfaces/DynamcisInterviewFeedback';

export interface GetFeedbackViewingPrivilegesAction extends Action<typeof ActionTypes.GET_FEEBACK_VIEWING_PRIVILEGES> {
    feedbackDetails: DynamcisInterviewFeedback;
}

export interface DeleteFeedbackViewingPrivilegeMemberAction extends Action<typeof ActionTypes.DELETE_FEEBACK_VIEWING_PRIVILEGE> {
    feedbackDetails: DynamcisInterviewFeedback;
}

export interface AddFeedbackViewingPrivilegeMemberAction extends Action<typeof ActionTypes.ADD_FEEBACK_VIEWING_PRIVILEGE> {
    feedbackDetails: DynamcisInterviewFeedback;
}

const getFeedbackViewingPrivileges = (feedbackDetails: DynamcisInterviewFeedback): GetFeedbackViewingPrivilegesAction => ({
    type: ActionTypes.GET_FEEBACK_VIEWING_PRIVILEGES,
    feedbackDetails: feedbackDetails
});

const deleteFeedbackViewingPrivilegeMember = (feedbackDetails: DynamcisInterviewFeedback): DeleteFeedbackViewingPrivilegeMemberAction => ({
    type: ActionTypes.DELETE_FEEBACK_VIEWING_PRIVILEGE,
    feedbackDetails: feedbackDetails
});

const addFeedbackViewingPrivilegeMember = (feedbackDetails: DynamcisInterviewFeedback): AddFeedbackViewingPrivilegeMemberAction => ({
    type: ActionTypes.ADD_FEEBACK_VIEWING_PRIVILEGE,
    feedbackDetails: feedbackDetails
});

export const getFeedbackViewingPrivilegesThunk = (
    jobID: string,
    jobApplicationID: string
): ThunkAction<Promise<DynamcisInterviewFeedback>, void, void, GetFeedbackViewingPrivilegesAction> => async (
    dispatch: ThunkDispatch<void, void, GetFeedbackViewingPrivilegesAction>
): Promise<DynamcisInterviewFeedback> =>
    executeWithTelemetry(
        'GetFeedbackViewingPrivileges',
        async (event: TimedTelemetryEvent): Promise<DynamcisInterviewFeedback> => {
            const response = await services.http
                .get(`${config.serviceEndpoint}/v1/dynamicsextension/${jobID}/applications/${jobApplicationID}/participants`)
                .catch((): void => event.reject());
            event.resolve();
            let participants: DynamcisInterviewFeedback = {};
            if (response) {
                participants = await response.body.json();
            }
            dispatch(getFeedbackViewingPrivileges(participants));
            return participants;
        }
    );

export const deleteFeedbackViewingPrivilegeMemberThunk = (
    jobApplicationID: string,
    userId: string,
    participants: DynamcisInterviewFeedback
): ThunkAction<Promise<void>, void, void, DeleteFeedbackViewingPrivilegeMemberAction> => async (
    dispatch: ThunkDispatch<void, void, DeleteFeedbackViewingPrivilegeMemberAction>
): Promise<void> =>
    executeWithTelemetry(
        'DeleteFeedbackViewingPrivilegeMember',
        async (event: TimedTelemetryEvent): Promise<void> => {
            const response = await services.http
                .delete(`${config.serviceEndpoint}/v1/dynamicsextension/${jobApplicationID}/participants/${userId}`, {})
                .catch((): void => event.reject());
            event.resolve();

            if (response) {
                const isDeleted = await response.body.json();
                if (isDeleted === true && participants.interviewers) {
                    participants.interviewers = participants.interviewers.filter((a: HiringTeamMember): boolean => {
                        return a.objectId !== userId;
                    });
                }
            }
            dispatch(deleteFeedbackViewingPrivilegeMember(participants));
        }
    );

export const addFeedbackViewingPrivilegeMemberThunk = (
    jobApplicationID: string,
    userId: string,
    participants: DynamcisInterviewFeedback
): ThunkAction<Promise<void>, void, void, AddFeedbackViewingPrivilegeMemberAction> => async (
    dispatch: ThunkDispatch<void, void, AddFeedbackViewingPrivilegeMemberAction>
): Promise<void> =>
    executeWithTelemetry(
        'AddFeedbackViewingPrivilegeMember',
        async (event: TimedTelemetryEvent): Promise<void> => {
            const response = await services.http
                .post(`${config.serviceEndpoint}/v1/dynamicsextension/${jobApplicationID}/participants/${userId}`, {})
                .catch((): void => event.reject());
            event.resolve();

            let newPrivilegeMember: HiringTeamMember = {};
            if (response) {
                newPrivilegeMember = await response.body.json();
                if (newPrivilegeMember && newPrivilegeMember.objectId) {
                    if (!participants.interviewers) {
                        participants.interviewers = [];
                    }
                    participants.interviewers.push(newPrivilegeMember);
                }
            }
            dispatch(addFeedbackViewingPrivilegeMember(participants));
        }
    );

export type Actions =
    | GetFeedbackViewingPrivilegesAction
    | DeleteFeedbackViewingPrivilegeMemberAction
    | AddFeedbackViewingPrivilegeMemberAction;

import { Actions } from '../../../actions/ur/metadata/metadata';
import * as ActionTypes from '../../../actions/ur/metadata/actionTypes';
import { InterviewFeedbackMetadata } from '../../../../environment/interfaces/InterviewFeedbackMetadata';

export interface MetadataStoreState {
    isLoading: boolean;
    metadata?: InterviewFeedbackMetadata[];
}

const initialState: MetadataStoreState = {
    isLoading: true,
    metadata: []
};

const reducer = (state: MetadataStoreState = initialState, action: Actions): MetadataStoreState => {
    switch (action.type) {
        case ActionTypes.SET_ISLOADING:
            return { ...state, isLoading: true };
        case ActionTypes.GET_METADATA:
            return { ...state, metadata: action.metadata || initialState.metadata, isLoading: false };
        default:
            return state;
    }
};

export default reducer;

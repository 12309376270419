import React, { useEffect, useRef, useState } from 'react';
import { RouterProps } from 'react-router';
import { PrimaryButton, Stack, StackItem, Spinner, SpinnerSize, Text } from 'office-ui-fabric-react';
import { useMsal } from '@azure/msal-react';
import { AuthError } from '@azure/msal-browser';

import { dom, es } from '@gta/core';

import { Condition } from '../../framework/components/Condition';

import styles from './Login.module.css';

export const Login: React.FunctionComponent<RouterProps> = (props: RouterProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true);
    const loginRef = useRef<boolean>();
    const { instance } = useMsal();

    const onSignInComplete = (): void => {
        props.history.push(es.routeToUrl(dom.getEntryRoute({ url: '/' })));
    };

    const authenticateSilently = async (): Promise<void> => {
        instance
            .handleRedirectPromise()
            .then((): void => {
                const account = instance.getActiveAccount();
                if (account) {
                    onSignInComplete();
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error): void => {
                if (error instanceof AuthError) {
                    // clears only refer localstorage
                    if ('localStorage' in window) {
                        window.localStorage.clear();
                    }
                }
            })
            .finally((): void => {
                if (loginRef.current) {
                    setIsLoading(false);
                }
            });
    };

    useEffect((): (() => void) => {
        loginRef.current = true;
        authenticateSilently();
        return (): void => {
            loginRef.current = false;
        };
    }, []);

    const onSignIn = async (): Promise<void> => {
        if (dom.inIframe()) {
            setIsLoading(true);
            const token = await instance.loginPopup().catch((): void => undefined);
            if (token) {
                onSignInComplete();
            } else {
                setIsLoading(false);
            }
        } else {
            instance.loginRedirect();
        }
    };

    return (
        <Condition if={!isLoading} else={<Spinner className={styles.loginSpinner} size={SpinnerSize.large} label="Signing you in" />}>
            <Stack tokens={{ childrenGap: 10 }} className={styles.loginPage}>
                <StackItem align="center">
                    <Text as="h2" variant="xLarge">
                        Please sign in
                    </Text>
                </StackItem>

                <StackItem align="center">
                    <Text as="p">(In case you run into any issues, please refresh this page.)</Text>
                </StackItem>

                <StackItem align="center">
                    <PrimaryButton ariaLabel="Sign in and authenticate for Referral" onClick={onSignIn} text="Sign In" />
                </StackItem>
            </Stack>
        </Condition>
    );
};

import React, { useRef } from 'react';
import { getId, IconButton, Label, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from 'office-ui-fabric-react';

import { Condition } from '../../framework/components/Condition';
import errorCodes from './errorCodes';

import styles from './FilePicker.module.css';

export interface FilePickerProps {
    acceptedFileExtensions?: string[];
    allowedFileSizeInMB?: number;
    buttonText?: string;
    disabled?: boolean;
    errorMessage?: string;
    fileNames?: string[];
    label?: string;
    maxFileNameLength?: number;
    maxNumberoOfFilesToUpload?: number;
    multiple?: boolean;
    onChange?(event: FileEvent): void;
    onClearSelectedFiles?(): void;
    required?: boolean;
}

export interface FileEvent {
    event: React.ChangeEvent<HTMLInputElement> | undefined;
    errorMessage: string;
    fileDispayNames: string[];
}

const FilePicker = ({
    allowedFileSizeInMB = 1024,
    disabled = false,
    errorMessage = '',
    maxFileNameLength = 100,
    maxNumberoOfFilesToUpload = 3,
    required = false,
    acceptedFileExtensions,
    buttonText,
    fileNames,
    label,
    multiple,
    onChange,
    onClearSelectedFiles
}: FilePickerProps): JSX.Element => {
    const [statusMessage, setStatusMessage] = React.useState<boolean>(false);
    const buttonId = getId('buttonClick');

    const primaryButtonRef = useRef<HTMLInputElement>(null);

    const megaBytesToBytes = 1048576;

    const openFileBrowser = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        if (primaryButtonRef.current) {
            setStatusMessage(false);
            const element = event.target as HTMLInputElement;
            element.value = '';
            primaryButtonRef.current.click();
        }
    };

    const parseExtensionFromFileName = (fileName: string): string => {
        const lastDelimeterIndex = fileName.lastIndexOf('.');
        const extension = fileName.substring(lastDelimeterIndex + 1);
        return extension.toLowerCase();
    };

    const validateFileSize = (files: File[]): string => {
        return files.some((file: File): boolean => file.size > allowedFileSizeInMB * megaBytesToBytes) ? errorCodes.invalidFileSize : '';
    };

    const validateFileExtension = (files: File[]): string => {
        if (acceptedFileExtensions) {
            return !files.every(
                (file: File): boolean =>
                    (acceptedFileExtensions as string[]).findIndex(
                        (extension: string): boolean => extension === parseExtensionFromFileName(file.name)
                    ) > -1
            )
                ? errorCodes.invalidFileExtension
                : '';
        }

        return '';
    };

    const validateNumberOfFiles = (files: File[]): string => {
        return files.length > maxNumberoOfFilesToUpload ? errorCodes.invalidNumberOfFiles : '';
    };

    const validateFileNameLength = (files: File[]): string => {
        return files.some((file: File): boolean => file.name.length > maxFileNameLength) ? errorCodes.invalidFileNameLength : '';
    };

    const validateEmptyFile = (files: File[]): string => {
        return files.some((file: File): boolean => file.size === 0) ? errorCodes.invalidFile : '';
    };

    const validateFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fileEvent: FileEvent = {
            event: event,
            errorMessage: '',
            fileDispayNames: []
        };

        if (event.target.files && event.target.files.length > 0) {
            const files: File[] = Array.from(event.target.files);

            for (let i = 0; i < event.target.files.length; i++) {
                let fileSizeInKB = (event.target.files[i].size / 1024).toFixed(2);
                fileEvent.fileDispayNames.push(event.target.files[i].name + ' (' + fileSizeInKB + ' KB)');
            }

            const errorMessages = [
                validateFileSize(files),
                validateFileExtension(files),
                validateFileNameLength(files),
                validateEmptyFile(files),
                validateNumberOfFiles(files)
            ];
            fileEvent.errorMessage = errorMessages.filter((error: string): boolean => !!error).join(' ');
        } else {
            fileEvent.fileDispayNames.push('');
        }

        if (onChange) {
            onChange(fileEvent);
        }
    };

    React.useEffect((): (() => void) => {
        let timeoutId: ReturnType<typeof setTimeout>;
        if (fileNames && fileNames.length && !errorMessage) {
            setStatusMessage(true);
            /* show message for 30 seconds */
            timeoutId = setTimeout((): void => {
                setStatusMessage(false);
            }, 30000);
        } else {
            setStatusMessage(false);
        }
        return (): void => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [fileNames]);

    return (
        <>
            <input hidden type="file" onClick={openFileBrowser} onChange={validateFile} multiple={multiple} ref={primaryButtonRef} />
            {label && (
                <Label htmlFor={buttonId} required={required}>
                    {label}
                </Label>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow={true}>
                    <Stack horizontal>
                        <Stack.Item grow={true}>
                            <TextField
                                readOnly
                                ariaLabel={label}
                                disabled={disabled}
                                onClick={openFileBrowser}
                                value={fileNames ? fileNames.join(' ') : ''}
                                errorMessage={errorMessage}
                                tabIndex={-1}
                            />
                            <div role="status" aria-live="polite">
                                {statusMessage && (
                                    <MessageBar
                                        messageBarType={MessageBarType.info}
                                        isMultiline={false}
                                        styles={{ root: { marginTop: '1px' } }}
                                    >
                                        File has been attached successfully.
                                    </MessageBar>
                                )}
                            </div>
                        </Stack.Item>
                        <Stack.Item className={styles.clear}>
                            <Condition if={!(!fileNames || fileNames.join(' ') === '')}>
                                <IconButton
                                    disabled={disabled}
                                    ariaLabel="Clear file selection"
                                    className={styles.clearIcon}
                                    iconProps={{ iconName: 'Clear' }}
                                    onClick={onClearSelectedFiles}
                                    title="Clear"
                                />
                            </Condition>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item align="start">
                    <PrimaryButton
                        ariaLabel={`Browse button ${label}`}
                        disabled={disabled}
                        id={buttonId}
                        text={buttonText}
                        onClick={openFileBrowser}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
};

export default FilePicker;

import React from 'react';

import { ErrorDialog } from '../ErrorDialog/ErrorDialog';
import { useErrorService, UseErrorServiceParams } from '../../hooks/useErrorService';

export type ErrorServiceDialogProps = UseErrorServiceParams;

export const errorCodePropertyName = 'Error code';

export const ErrorServiceDialog: React.FunctionComponent<ErrorServiceDialogProps> = (props: ErrorServiceDialogProps): JSX.Element => {
    const [isErrorDialogHidden, setIsErrorDialogHidden, errorRef] = useErrorService(props);

    const onCloseErrorDialog = (): void => {
        errorRef.current = null;
        setIsErrorDialogHidden(true);
    };

    return (
        <>
            {errorRef.current && <ErrorDialog error={errorRef.current} isHidden={isErrorDialogHidden} onCloseDialog={onCloseErrorDialog} />}
        </>
    );
};

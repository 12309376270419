import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DefaultButton, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text } from 'office-ui-fabric-react';

import styles from './CandidateConsent.module.css';

export interface RouteParams {
    referId: string;
}

export interface CandidateConsentProps extends Partial<RouteComponentProps<RouteParams>> {
    isLoading: boolean;
    onSubmitConsent?(showStatus: boolean, referId: string): void;
}

const CandidateConsent = (props: CandidateConsentProps): JSX.Element => {
    const [validationMessage, setValidationMessage] = useState('');

    const referId = props.match && props.match.params ? props.match.params.referId : '';

    const submitCandidateConsent = (event: React.MouseEvent<HTMLButtonElement>, isConsented: boolean): void => {
        if (isConsented === true) {
            if (props.onSubmitConsent) {
                props.onSubmitConsent(isConsented, referId);
            }

            setValidationMessage(`We'll let them see how you're doing, thanks!`);
        } else {
            if (props.onSubmitConsent) {
                props.onSubmitConsent(isConsented, referId);
            }

            setValidationMessage(`We'll not let them see how you're doing, thanks!`);
        }
    };

    return (
        <>
            <h1 className="visually-hidden">Do you want to share your application status?</h1>
            <Stack className={styles.body}>
                <Stack className={styles.consentForm}>
                    <StackItem className={styles.consentContent}>
                        <Text block className={styles.bottomMargin}>
                            Do you want to share your application status?
                        </Text>
                        <Text block className={styles.bottomMargin}>
                            {`Your acquaintance may have referred you, but it's up to you whether they can follow your progress. Do you want them to see the general status of your application?`}
                        </Text>
                        <div className={styles.centerAlign}>
                            <PrimaryButton
                                disabled={props.isLoading}
                                ariaLabel="Agree to share status with referee"
                                onClick={(event: React.MouseEvent<HTMLButtonElement>): void => submitCandidateConsent(event, true)}
                                className={styles.buttons}
                                text="Yes"
                            />
                            <DefaultButton
                                disabled={props.isLoading}
                                ariaLabel="Disagree to share status with referee"
                                onClick={(event: React.MouseEvent<HTMLButtonElement>): void => submitCandidateConsent(event, false)}
                                className={styles.buttons}
                                text="No"
                            />
                        </div>
                        {props.isLoading ? (
                            <div className={styles.centerAlign} aria-label="Sending the selected response" role="alert" aria-busy="true">
                                <Spinner size={SpinnerSize.large} />
                            </div>
                        ) : (
                            validationMessage !== '' && (
                                <div className={styles.centerAlign} role="alert" aria-live="polite">
                                    <Text block className={styles.bottomMargin}>
                                        {validationMessage}
                                    </Text>
                                    <Text block> To change this choice later, use the same email link.</Text>
                                </div>
                            )
                        )}
                    </StackItem>

                    <StackItem className={styles.consentFooter}>
                        <footer>
                            <img
                                alt="Microsoft"
                                src={`${process.env.PUBLIC_URL}/assets/images/microsoft-logo/microsoft-logo.png`}
                                className={styles.microsoftLogo}
                            />
                            <div className={styles.footerText}>
                                <Text block>Microsoft Corporation</Text>
                                <Text block>One Microsoft Way</Text>
                                <Text block>Redmond, WA, USA 98052</Text>
                            </div>
                            <Text>Microsoft respects your privacy. Review our online </Text>
                            <a href="http://go.microsoft.com/fwlink/?LinkId=518021" target="_blank" rel="noopener noreferrer">
                                Microsoft Data Privacy Notice (DPN)
                            </a>
                        </footer>
                    </StackItem>
                </Stack>
            </Stack>
        </>
    );
};

export default CandidateConsent;

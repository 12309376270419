import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ITag } from 'office-ui-fabric-react';

import { TimedTelemetryEvent } from '@gta/core';

import * as ActionTypes from './actionTypes';
import { config, services } from '../../../environment';
import { JobDetails, Refer, ReferDetails, ReferFormModel } from '../../../environment/interfaces';
import { executeWithTelemetry } from '../../../environment/services/telemetry';
import { Acquaintance, CountryCode, ReferEndorse } from '../../../environment/contracts';

export interface GetJobSuggestionsAction extends Action<typeof ActionTypes.GET_JOB> {
    job: JobDetails | null;
}

export interface PostGetReferDataAction extends Action<typeof ActionTypes.POST_GET_REFER_DATA> {
    candidateFirstName: string | null;
    candidateLastName: string | null;
    candidateEmail: string | null;
    candidatePhone: string | null;
    location: CountryCode | null;
    profile: string | null;
    resumeFile: File | null;
    linkedInURL: string | null;
    acquaintanceLevel: Acquaintance | null;
    isEndorsed: ReferEndorse | null;
    isUniversityStudent: boolean | null;
    additionalInformation: string | null;
}

export interface PostSubmitReferralFormAction extends Action<typeof ActionTypes.POST_REFERRALFORM_SUBMISSION> {
    showNotificationBar: boolean;
}

export type GetReferAgainDataAction = Action<typeof ActionTypes.GET_REFER_AGAIN_DATA>;

export type RequestSubmitReferralFormAction = Action<typeof ActionTypes.REQUEST_REFERRALFORM_SUBMISSION>;

export type DismissNotificationAction = Action<typeof ActionTypes.DISMISS_NOTIFICATION>;

type ReferralFormAction =
    | PostSubmitReferralFormAction
    | RequestSubmitReferralFormAction
    | DismissNotificationAction
    | GetReferAgainDataAction
    | PostGetReferDataAction;

const getJobSuggestionsAction = (job: JobDetails | null): GetJobSuggestionsAction => ({
    type: ActionTypes.GET_JOB,
    job
});

const getPreviousReferDataAction = (): GetReferAgainDataAction => ({
    type: ActionTypes.GET_REFER_AGAIN_DATA
});

const postGetReferData = (referData: ReferDetails | null): PostGetReferDataAction => ({
    type: ActionTypes.POST_GET_REFER_DATA,
    candidateFirstName: referData && referData.firstName ? referData.firstName : null,
    candidateLastName: referData && referData.lastName ? referData.lastName : null,
    candidateEmail: referData && referData.candidateEmail ? referData.candidateEmail : null,
    candidatePhone: referData && referData.candidatePhone ? referData.candidatePhone : null,
    location: referData && referData.location ? referData.location : null,
    profile: referData && referData.profile ? referData.profile : null,
    resumeFile: referData && referData.resumeFile ? referData.resumeFile : null,
    linkedInURL: referData && referData.linkedInURL ? referData.linkedInURL : null,
    acquaintanceLevel: referData && referData.acquaintanceLevel ? referData.acquaintanceLevel : null,
    isEndorsed: referData && referData.isEndorsed ? referData.isEndorsed : null,
    isUniversityStudent: referData && referData.isUniversityStudent ? referData.isUniversityStudent : null,
    additionalInformation: referData && referData.additionalInformation ? referData.additionalInformation : null
});

const requestReferralFormSubmission = (): RequestSubmitReferralFormAction => ({
    type: ActionTypes.REQUEST_REFERRALFORM_SUBMISSION
});

const postReferralFormSubmission = (showNotificationBar: boolean): PostSubmitReferralFormAction => ({
    type: ActionTypes.POST_REFERRALFORM_SUBMISSION,
    showNotificationBar
});

const dismissNotification = (): DismissNotificationAction => ({
    type: ActionTypes.DISMISS_NOTIFICATION
});

export const getJobSuggestionsFormThunk = (
    jobID: string
): ThunkAction<Promise<JobDetails | null>, void, void, GetJobSuggestionsAction> => async (
    dispatch: ThunkDispatch<void, void, GetJobSuggestionsAction>
): Promise<JobDetails | null> =>
    await executeWithTelemetry(
        'GetJobSuggestions',
        async (event: TimedTelemetryEvent): Promise<JobDetails | null> => {
            const response = await services.http.get(`${config.serviceEndpoint}/v2/job/${jobID}`).catch((): void => event.reject());
            event.resolve();
            let job: JobDetails | null = null;
            if (response) {
                const responseJson = await response.body.json().catch((): void => undefined);
                if (responseJson) {
                    job = { jobId: responseJson.jobId, jobRoleName: responseJson.jobRoleName };
                }
            }
            dispatch(getJobSuggestionsAction(job));
            return job;
        }
    );

export const getReferDetailsFormThunk = (
    referId: string
): ThunkAction<Promise<ReferDetails | null>, void, void, ReferralFormAction> => async (
    dispatch: ThunkDispatch<void, void, ReferralFormAction>
): Promise<ReferDetails | null> =>
    await executeWithTelemetry(
        'GetReferDetails',
        async (event: TimedTelemetryEvent): Promise<ReferDetails | null> => {
            dispatch(getPreviousReferDataAction());
            const response = await services.http
                .get(`${config.serviceEndpoint}/v2/refer/getreferral/${referId}`)
                .catch((): void => event.reject());
            event.resolve();
            let referDetails: ReferDetails | null = null;
            if (response) {
                const responseJson = await response.body.json().catch((): void => undefined);
                if (responseJson) {
                    referDetails = {
                        ...responseJson,
                        candidatePhone: responseJson.phone
                    };
                }
            }
            dispatch(postGetReferData(referDetails));
            return referDetails;
        }
    );

export const submitReferralFormThunk = (referForm: ReferFormModel): ThunkAction<Promise<void>, void, void, ReferralFormAction> => async (
    dispatch: ThunkDispatch<void, void, ReferralFormAction>
): Promise<void> =>
    executeWithTelemetry(
        'SubmitReferralForm',
        async (event: TimedTelemetryEvent): Promise<void> => {
            dispatch(requestReferralFormSubmission());

            const jobIds = referForm.jobs && referForm.jobs.map((job: ITag): string => String(job.key));
            delete referForm.jobs;
            const refer: Refer = { ...referForm, jobIds };

            const referData = new FormData();
            const referDataKeys = Object.keys(refer) as (keyof Refer)[];

            for (const key of referDataKeys) {
                if (refer[key] instanceof Array) {
                    for (const value of refer[key] as string[]) {
                        referData.append(`${key}[]`, value);
                    }
                } else {
                    referData.append(key, refer[key] as string | File);
                }
            }
            let showNotificationBar = true;
            await services.http.post(`${config.serviceEndpoint}/v2/refer`, referData).catch((): void => {
                showNotificationBar = false;
                event.reject();
            });

            event.resolve();
            dispatch(postReferralFormSubmission(showNotificationBar));
            window.setTimeout((): void => {
                dispatch(dismissNotification());
            }, 10000);
        }
    );

export const dismissNotificationBarThunk = (): ThunkAction<Promise<void>, void, void, DismissNotificationAction> => {
    return async (dispatch: ThunkDispatch<void, void, DismissNotificationAction>): Promise<void> => {
        dispatch(dismissNotification());
    };
};

export type Actions =
    | GetReferAgainDataAction
    | PostGetReferDataAction
    | GetJobSuggestionsAction
    | PostSubmitReferralFormAction
    | RequestSubmitReferralFormAction
    | DismissNotificationAction;

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import {
    createApplicationInsightsTelemetryProvider,
    createConsoleTelemetryProvider,
    createLogTimedEventDecorator,
    createTelemetryClient,
    TelemetryClient,
    TelemetryProvider,
    TimedTelemetryEvent
} from '@gta/core';

import { config } from '../config';

export const telemetryProperties = {
    application: config.application,
    serviceEndpoint: config.serviceEndpoint,
    sessionId: config.sessionId,
    buildId: config.build.id
};

function configureTelemetryClient(): [TelemetryClient, TelemetryProvider<ApplicationInsights> | undefined] {
    let applicationInsightsProvider: TelemetryProvider<ApplicationInsights> | undefined = undefined;
    const telemetryProviders: TelemetryProvider[] = [];

    if (config.telemetry.applicationInsights.config.instrumentationKey) {
        applicationInsightsProvider = createApplicationInsightsTelemetryProvider(config.telemetry.applicationInsights);
        telemetryProviders.push(applicationInsightsProvider);
    }

    if (config.telemetry.logToConsole) {
        telemetryProviders.push(createConsoleTelemetryProvider());
    }
    const telemetryClient = createTelemetryClient(telemetryProviders, telemetryProperties);

    return [telemetryClient, applicationInsightsProvider];
}

export const [telemetryClient, applicationInsightsProvider] = configureTelemetryClient();
export const logEvent = createLogTimedEventDecorator(telemetryClient);
export const executeWithTelemetry = <T>(eventName: string, action: (event: TimedTelemetryEvent) => T): T => {
    const wrappedAction = logEvent(eventName)(
        (): T => {
            const event = telemetryClient.peek() as TimedTelemetryEvent;
            return action(event);
        }
    );

    return wrappedAction();
};

import React from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from 'office-ui-fabric-react';

import styles from './ErrorDialog.module.css';

export interface BenignErrorDetails {
    type: 'benign';
}

export interface FatalErrorDetails {
    type: 'fatal';
    reportDetails: ReportErrorDetails;
}

export interface CommonErrorDetails {
    title: string;
    message: string;
    details: Record<string, string>;
}

export type ErrorDetails = CommonErrorDetails & (BenignErrorDetails | FatalErrorDetails);

export interface ReportErrorDetails {
    to: string;
    cc: string;
    subject: string;
    body: string;
}

export interface ErrorDialogProps {
    isHidden: boolean;
    onCloseDialog?(): void;
    error: ErrorDetails;
}

export const ErrorDialog: React.FunctionComponent<ErrorDialogProps> = (props: ErrorDialogProps): JSX.Element => {
    const isFatalError = props.error.type === 'fatal';

    const reportProblem = (): void => {
        const reportDetails = (props.error as FatalErrorDetails).reportDetails;
        const mailParams = Object.keys(reportDetails)
            .filter((key: string): boolean => key !== ('to' as keyof ReportErrorDetails))
            .map((key: string): string => {
                const value = reportDetails[key as keyof ReportErrorDetails];
                return value ? `${key}=${value}` : '';
            })
            .join('&');
        window.location.assign(`mailto:${reportDetails.to}?${mailParams}`);
    };

    return (
        <Dialog
            hidden={props.isHidden}
            maxWidth={400}
            dialogContentProps={{
                title: props.error.title,
                type: DialogType.normal,
                showCloseButton: false,
                onDismiss: props.onCloseDialog
            }}
            modalProps={{
                onDismiss: props.onCloseDialog,
                isBlocking: false
            }}
        >
            <span className={styles.errorDetails}>
                {props.error.message + '\n\n'}
                {isFatalError &&
                    Object.keys(props.error.details)
                        .map((key: string): string => `${key}: ${props.error.details[key]} `)
                        .join('\n')}
            </span>

            <DialogFooter>
                {isFatalError && <PrimaryButton onClick={reportProblem} text="Report problem" />}
                <DefaultButton onClick={props.onCloseDialog} text="Close" />
            </DialogFooter>
        </Dialog>
    );
};

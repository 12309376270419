import { createErrorService } from '@gta/core';

export const errorService = createErrorService([
    {
        code: 'InvalidRequestException',
        title: 'Not all fields are filled',
        isFatal: true,
        message: 'Please fill the required fields and try again.'
    },
    {
        code: 'InvalidFileFormatException',
        title: 'Invalid file format',
        isFatal: true,
        message:
            `File format is not supported. ` +
            `Acceptable file formats are ( .doc, .docx, .pdf, .htm, .html, .txt, .odt, .tif, .tiff, .rtf ). Please try again.`
    },
    {
        code: 'LargeFileUploadException',
        title: 'File size exceeded',
        isFatal: true,
        message: 'The resume file is too large to be uploaded. Max size is 5MB. Please try again.'
    },
    {
        code: 'EmptyFileException',
        title: 'File is empty',
        isFatal: true,
        message: 'File uploaded is empty. Please try again.'
    },
    {
        code: 'DataNotFoundException',
        title: 'Referral data not found',
        isFatal: true,
        message: 'Could not find referral data for the given job refer id.'
    },
    {
        code: 'StringToGuidConversionException',
        title: 'Error while string to guid conversion',
        isFatal: true,
        message: 'Error while converting string to guid.'
    },
    {
        code: '401',
        title: 'Unauthorized/Token Expired',
        isFatal: true,
        message: 'Please refresh the page and try again.'
    }
]);

import React from 'react';
import { Stack, Text, ActionButton, StackItem } from 'office-ui-fabric-react';

import { InterviewFeedbackProps } from '../InterviewFeedback/InterviewFeedback';
import { urIVFeedbackDashboardRoute } from '../../../environment/constants/ur';

import styles from './InterviewFeedbackHeader.module.css';

export const InterviewFeedbackHeader: React.FunctionComponent<InterviewFeedbackProps> = (props: InterviewFeedbackProps): JSX.Element => {
    const getFormattedDate = (date?: string): string => {
        if (date) {
            const selectedDate = new Date(date); // pass in date param here
            const formattedDate = `${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`;
            return formattedDate;
        }
        return '';
    };

    const onBackToCandidatesButtonClick = (): void => {
        if (props.history) {
            props.history.push(urIVFeedbackDashboardRoute);
        }
    };

    return (
        <div className={styles.section}>
            <Stack>
                <StackItem>
                    <ActionButton
                        iconProps={{ iconName: 'Back' }}
                        allowDisabledFocus={true}
                        onClick={(): void => onBackToCandidatesButtonClick()}
                        ariaLabel="Back to candidates"
                        title="Back to candidates"
                    >
                        Back to candidates
                    </ActionButton>
                </StackItem>
                <StackItem>
                    <Text as="h2" className={styles.title} variant={'xLarge'}>
                        {props.feedbackDetails.candidateName}
                    </Text>
                </StackItem>
            </Stack>
            <Text className={styles.metadata} variant={'medium'}>
                Req ID: {!props.feedbackDetails.externalJobId ? props.feedbackDetails.jobID : props.feedbackDetails.externalJobId}
            </Text>
            <Text className={styles.metadata} variant={'medium'}>
                Hiring Manager: {props.feedbackDetails.hiringManagerName}
            </Text>
            <Text className={styles.metadata} variant={'medium'}>
                Job: {props.feedbackDetails.jobTitle}
            </Text>
            <Text className={styles.metadata} variant={'medium'}>
                Applied: {getFormattedDate(props.feedbackDetails.appliedOn)}
            </Text>
            <Text className={styles.metadata} variant={'medium'}>
                Current Stage: {props.feedbackDetails.currentStageName}
            </Text>
        </div>
    );
};

import React from 'react';
import { RouteProps, RouterProps } from 'react-router';
import { Stack, StackItem, Text, Persona, IPersonaSharedProps, PersonaSize, PersonaPresence } from 'office-ui-fabric-react';

import { Person } from '../../../environment/interfaces/person';

import styles from './DefaultLayout.module.css';

export interface DefaultLayoutProps extends Partial<RouterProps>, RouteProps {
    userProfile: Person;
    children?: React.ReactNode;
}

export const DefaultLayout: React.FunctionComponent<DefaultLayoutProps> = (props: DefaultLayoutProps): JSX.Element => {
    const personaProps: IPersonaSharedProps = { text: props.userProfile.fullName };
    return (
        <>
            <Stack horizontal className={styles.shell} horizontalAlign={'space-between'}>
                <StackItem align={'center'}>
                    <Text as="h4" className={styles.heading} variant="mediumPlus">
                        Interview Feedback
                    </Text>
                </StackItem>
                <StackItem align={'center'}>
                    <Stack horizontal className={styles.shell}>
                        <Stack className={styles.shell}>
                            <StackItem>
                                <Text className={styles.userProfile} variant="small">
                                    {props.userProfile.fullName}
                                </Text>
                            </StackItem>
                            <StackItem>
                                <Text className={styles.userPrincipalName} variant="small">
                                    {props.userProfile.email}
                                </Text>
                            </StackItem>
                        </Stack>
                        <StackItem align={'center'}>
                            <span className={styles.profileImage}>
                                <Persona
                                    {...personaProps}
                                    size={PersonaSize.size32}
                                    presence={PersonaPresence.none}
                                    hidePersonaDetails={true}
                                />
                            </span>
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack>
            {props.children}
        </>
    );
};

import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ReferAppStoreState } from '../../../store/reducers';
import { ThunkDispatch } from 'redux-thunk';

import {
    addFeedbackViewingPrivilegeMemberThunk,
    deleteFeedbackViewingPrivilegeMemberThunk,
    getFeedbackViewingPrivilegesThunk,
    getPeopleThunk
} from '../../../store/actions';
import { DynamcisInterviewFeedback } from '../../../environment/interfaces/DynamcisInterviewFeedback';
import {
    FeedbackViewingPrivileges,
    FeedbackViewingPrivilegesProps
} from '../../../components/UR/FeedbackViewingPrivileges/FeedbackViewingPrivileges';
import { GraphUser } from '../../../environment/interfaces/GraphUser';

const mapStateToProps = (state: ReferAppStoreState): FeedbackViewingPrivilegesProps => ({
    ...state.dynFeedbackPrivilege,
    feedbackDetails: state.dynFeedbackPrivilege.feedbackDetails,
    isLoading: state.dynFeedbackPrivilege.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, AnyAction>): Partial<FeedbackViewingPrivilegesProps> => ({
    onGetFeedbackViewingPrivileges: (jobId: string, applicationId: string): Promise<DynamcisInterviewFeedback> =>
        dispatch(getFeedbackViewingPrivilegesThunk(jobId, applicationId)),
    onDeleteMember: (applicationId: string, userId: string, participants: DynamcisInterviewFeedback): Promise<void> =>
        dispatch(deleteFeedbackViewingPrivilegeMemberThunk(applicationId, userId, participants)),
    onAddMember: (applicationId: string, userId: string, participants: DynamcisInterviewFeedback): Promise<void> =>
        dispatch(addFeedbackViewingPrivilegeMemberThunk(applicationId, userId, participants)),
    onGetPeople: (prefix: string): Promise<GraphUser[]> => dispatch(getPeopleThunk(prefix))
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackViewingPrivileges);

import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
    DetailsListLayoutMode,
    IColumn,
    CheckboxVisibility,
    ShimmeredDetailsList,
    DefaultButton,
    IButtonStyles
} from 'office-ui-fabric-react';

import { es } from '@gta/core';

import { ReferHistoryDetail } from '../../environment/interfaces';
import styles from './ReferHistory.module.css';
import { config } from '../../environment';

interface ReferHistoryView {
    roles: JSX.Element[];
    statuses: JSX.Element[];
    name: string;
    referredOn: string;
    actions: JSX.Element;
}

export interface ReferHistoryState {
    columns: IColumn[];
    items: ReferHistoryDetail[];
}

export interface ReferHistoryProps extends Partial<RouteComponentProps> {
    isLoading: boolean;
    historyDetails: ReferHistoryDetail[];
    onGetHistory?(): void;
    setPivotItem?: (key: string) => void;
}

const referButtonStyles: Partial<IButtonStyles> = {
    label: {
        marginLeft: '2px'
    },
    root: {
        padding: '0px 4px'
    }
};
class ReferHistory extends React.Component<ReferHistoryProps, ReferHistoryState> {
    public constructor(props: ReferHistoryProps) {
        super(props);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Name',
                fieldName: 'name',
                ariaLabel: 'Column operations based on candidate name, Press to sort based on candidate name',
                minWidth: 200,
                maxWidth: 220,
                isRowHeader: true,
                data: 'string',
                isMultiline: true,
                isPadded: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted NAME Column A to Z',
                sortDescendingAriaLabel: 'Sorted NAME Column Z to A',
                onColumnClick: this.onColumnClick
            },
            {
                key: 'column2',
                name: 'Referred On',
                fieldName: 'referredOn',
                minWidth: 100,
                maxWidth: 120,
                data: 'number',
                ariaLabel: 'Column operations based on referred date, Press to sort based on referred date',
                isMultiline: true,
                isPadded: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted REFERRED ON Column ascending',
                sortDescendingAriaLabel: 'Sorted REFERRED ON Column descending',
                onColumnClick: this.onColumnClick
            },
            {
                key: 'column3',
                name: 'Role (Job ID)',
                fieldName: 'roles',
                minWidth: 120,
                maxWidth: 150,
                data: 'string',
                isPadded: true,
                isMultiline: true,
                isResizable: true
            },
            {
                key: 'column4',
                name: 'Status',
                fieldName: 'statuses',
                minWidth: 200,
                maxWidth: 240,
                data: 'string',
                isMultiline: true,
                isResizable: true
            },
            {
                key: 'column5',
                name: 'Actions',
                fieldName: 'actions',
                minWidth: 120,
                maxWidth: 140,
                data: 'string',
                isMultiline: false,
                isResizable: false
            }
        ];

        this.referAgain = this.referAgain.bind(this);
        this.state = {
            items: [],
            columns: columns
        };
    }

    public async componentDidMount(): Promise<void> {
        if (this.props.onGetHistory) {
            this.props.onGetHistory();
        }
    }

    public referAgain(id: string): void {
        if (this.props && this.props.setPivotItem) {
            this.props.setPivotItem('referForm');
            let url = '?';
            if (this.props && this.props.location) {
                const params = es.parseQueryString(this.props.location.search);
                if (params && params.jobId) {
                    url += `jobId=${params.jobId}&`;
                }
            }
            url += `referId=${id}`;
            this.props.history && this.props.history.push(url);
        }
    }

    public componentWillReceiveProps(nextProps: ReferHistoryProps): void {
        this.setState({ items: nextProps.historyDetails });
    }

    public render(): JSX.Element {
        const { columns, items } = this.state;
        return (
            <>
                {!this.props.isLoading && items.length === 0 ? (
                    <p className={styles.noReferralsFound}>No previous referrals were found.</p>
                ) : (
                    <ShimmeredDetailsList
                        ariaLabelForGrid="Refer history"
                        items={items.map(
                            (historyRecord: ReferHistoryDetail): ReferHistoryView => ({
                                ...historyRecord,
                                referredOn: new Date(historyRecord.referredOn).toLocaleDateString(),
                                roles: historyRecord.roles.map(
                                    (role, index): JSX.Element =>
                                        role !== '' ? (
                                            <p key={index} className={styles.noTopMargin}>
                                                {role}
                                            </p>
                                        ) : (
                                            <p key={index} className={styles.noTopMargin}>
                                                &mdash;
                                            </p>
                                        )
                                ),
                                statuses: historyRecord.statuses.map(
                                    (status, index): JSX.Element => (
                                        <p key={index} className={styles.noTopMargin}>
                                            {status}
                                        </p>
                                    )
                                ),
                                actions: (
                                    <DefaultButton
                                        onClick={(): void => this.referAgain(historyRecord.referId)}
                                        className={styles.referAgainBtn}
                                        styles={referButtonStyles}
                                        disabled={historyRecord.statuses.includes('Hired')}
                                        ariaLabel="Refer your candidate to a new job"
                                        title="Refer your candidate to a new job"
                                        iconProps={{
                                            imageProps: {
                                                src: `${config.clientEndpoint}/assets/images/referagain.svg`,
                                                height: '16px',
                                                alt: 'refer again'
                                            }
                                        }}
                                    >
                                        Refer again
                                    </DefaultButton>
                                )
                            })
                        )}
                        columns={columns}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        setKey="set"
                        enableShimmer={this.props.isLoading}
                        disableSelectionZone={true}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                )}
            </>
        );
    }

    private onColumnClick = (_: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currentColumn: IColumn = newColumns.filter((currentCol): boolean => column.key === currentCol.key)[0];

        for (const newColumn of newColumns) {
            if (newColumn === currentColumn) {
                currentColumn.isSortedDescending = !currentColumn.isSortedDescending;
                currentColumn.isSorted = true;
            } else {
                newColumn.isSorted = false;
                newColumn.isSortedDescending = true;
            }
        }

        const newItems = items
            .slice(0)
            .sort(this.comparator(currentColumn.fieldName as string, currentColumn.data, currentColumn.isSortedDescending));
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };

    private comparator(
        columnKey: string,
        dataType: string,
        isSortedDescending?: boolean
    ): (leftItem: ReferHistoryDetail, rightItem: ReferHistoryDetail) => number {
        const key = columnKey as keyof ReferHistoryDetail;
        if (dataType === 'string') {
            return (leftItem: ReferHistoryDetail, rightItem: ReferHistoryDetail): number => {
                const leftItemAsString = (leftItem[key] as string).toLowerCase();
                const rightItemAsString = (rightItem[key] as string).toLowerCase();
                return (isSortedDescending ? leftItemAsString < rightItemAsString : leftItemAsString > rightItemAsString) ? 1 : -1;
            };
        } else {
            return (leftItem: ReferHistoryDetail, rightItem: ReferHistoryDetail): number =>
                (isSortedDescending ? leftItem[key] < rightItem[key] : leftItem[key] > rightItem[key]) ? 1 : -1;
        }
    }
}

export default ReferHistory;

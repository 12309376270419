import { dom } from '@gta/core';

import { msalInstance, tokenSilentRequest } from '../../environment/msalConfig';

export const createMsalGuard = (): (() => Promise<boolean>) => {
    const msalGaurd = async (): Promise<boolean | never> => {
        const [activeAccount] = await msalInstance.getAllAccounts();
        const isAuthenticated = await msalInstance
            .acquireTokenSilent(tokenSilentRequest(activeAccount))
            .then((): boolean => true)
            .catch((): boolean => false);
        if (!dom.inIframe() && !isAuthenticated) {
            await msalInstance.loginRedirect();
        } else {
            return await Promise.resolve(isAuthenticated);
        }
        return Promise.resolve(false);
    };
    return msalGaurd;
};

import { Actions } from '../../actions/refer/refer';
import * as ActionTypes from '../../actions/refer/actionTypes';
import { JobDetails } from '../../../environment/interfaces/JobDetails';
import { Acquaintance, ReferEndorse } from '../../../environment/contracts';

export interface ReferFormStoreState {
    candidateFirstName: string | null;
    candidateLastName: string | null;
    candidateEmail: string | null;
    candidatePhone: string | null;
    job: JobDetails | null;
    profile: string | null;
    resumeFile: File | null;
    linkedInURL: string | null;
    acquaintanceLevel: Acquaintance | null;
    isEndorsed: ReferEndorse | null;
    isUniversityStudent: boolean | null;
    additionalInformation: string | null;
    isFormSubmitting: boolean;
    isReferDetailsLoading: boolean;
    showNotificationBar: boolean;
}

const initialState: ReferFormStoreState = {
    candidateFirstName: null,
    candidateLastName: null,
    candidateEmail: null,
    candidatePhone: null,
    job: null,
    profile: null,
    resumeFile: null,
    linkedInURL: null,
    acquaintanceLevel: null,
    isEndorsed: null,
    isUniversityStudent: null,
    additionalInformation: null,
    isFormSubmitting: false,
    isReferDetailsLoading: false,
    showNotificationBar: false
};

const reducer = (state: ReferFormStoreState = initialState, action: Actions): ReferFormStoreState => {
    switch (action.type) {
        case ActionTypes.GET_JOB:
            return { ...state, job: action.job };
        case ActionTypes.GET_REFER_AGAIN_DATA:
            return { ...state, isReferDetailsLoading: true };
        case ActionTypes.POST_GET_REFER_DATA:
            return { ...state, ...action, isReferDetailsLoading: false };
        case ActionTypes.REQUEST_REFERRALFORM_SUBMISSION:
            return { ...state, isFormSubmitting: true };
        case ActionTypes.POST_REFERRALFORM_SUBMISSION:
            return { ...state, isFormSubmitting: false, showNotificationBar: action.showNotificationBar };
        case ActionTypes.DISMISS_NOTIFICATION:
            return { ...state, showNotificationBar: false };
        default: {
            return state;
        }
    }
};

export default reducer;

import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { InterviewFeedbackProps, InterviewFeedback } from '../../../components/UR/InterviewFeedback/InterviewFeedback';
import { ReferAppStoreState } from '../../../store/reducers';
import { getInterviewFeedbackThunk } from '../../../store/actions';
import { DynamcisInterviewFeedback } from '../../../environment/interfaces/DynamcisInterviewFeedback';
import { setIsLoadingThunk } from '../../../store/actions/ur/feedback/feedback';

const mapStateToProps = (state: ReferAppStoreState): InterviewFeedbackProps => ({
    ...state.interviewFeedback
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, AnyAction>): Partial<InterviewFeedbackProps> => ({
    onGetFeedback: (applicationId: string): Promise<DynamcisInterviewFeedback> => dispatch(getInterviewFeedbackThunk(applicationId)),
    setIsLoading: (): Promise<boolean> => dispatch(setIsLoadingThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewFeedback);

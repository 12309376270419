import {
    createHttpClient,
    createTelemetryHeadersInterceptor,
    dom,
    fetchBackend,
    HttpRequest,
    HttpRequestInterceptor,
    HttpResponse,
    TimedTelemetryEvent,
    withTelemetry
} from '@gta/core';

import { config } from '../config';
import { errorService } from './error';
import { telemetryClient, logEvent } from './telemetry';
import { ErrorTypes } from '../../framework/error';
import { msalInstance, tokenSilentRequest } from '../msalConfig';

const authHeaderName = 'Authorization';

export const createMsalHeaderInterceptor: HttpRequestInterceptor = async <T>(request: HttpRequest<T>): Promise<HttpRequest<T>> => {
    if (request && request.url && !request.url.includes('v2/candidate/consent')) {
        const activeAccount = msalInstance.getActiveAccount();
        const accounts = msalInstance.getAllAccounts();

        if (!activeAccount && accounts.length === 0) {
            if (dom.inIframe()) {
                await msalInstance.loginPopup();
            } else await msalInstance.loginRedirect();
        }

        const authResult = await msalInstance.acquireTokenSilent(tokenSilentRequest(activeAccount || accounts[0])).catch(
            async (err): Promise<void> => {
                // eslint-disable-next-line no-console
                console.log(err);
                await msalInstance.loginRedirect();
                return undefined;
            }
        );
        if (request && request.headers && !request.headers.get(authHeaderName)) {
            if (authResult && authResult.idToken) {
                request.headers.set(authHeaderName, `Bearer ${authResult.accessToken}`);
            }
        }
        return Promise.resolve(request);
    }
    return Promise.resolve(request);
};

const httpClient = createHttpClient({ defaultBaseUrl: config.serviceEndpoint }, fetchBackend);
httpClient.interceptors.request.push(createTelemetryHeadersInterceptor(telemetryClient, config.sessionId));
httpClient.interceptors.request.push(createMsalHeaderInterceptor);

export const httpClientWithTelemetry = withTelemetry(httpClient, ['get', 'post', 'delete', 'patch', 'put'], telemetryClient, logEvent, {
    onReject: async (name: string, result: HttpResponse, event: TimedTelemetryEvent): Promise<void> => {
        const error = await (result && result.body && result.body.json().catch((): void => undefined));
        const errorCode = (error && error.error && error.error.code) || (result.status === 401 && '401') || 'UNKNOWN_HTTP_ERROR';
        let isFatalException = undefined;
        if (error && error.error && error.error.customdetails && error.error.customdetails.ErrorType) {
            isFatalException = (error.error.customdetails.ErrorType.value as ErrorTypes) === ErrorTypes.Fatal ? true : false;
        }
        errorService.emitError(
            errorCode,
            {
                'Root activity': event.rootId,
                Activity: event.id
            },
            isFatalException
        );
    }
});

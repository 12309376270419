import React, { PropsWithChildren } from 'react';
import { RouteProps, RouteComponentProps, Route } from 'react-router';

import { AsyncRenderer } from '../../components/AsyncRenderer';
import { Condition } from '../../components/Condition';
import { RouteGuardSpinner } from '../../components/Loading/RouteGuardSpinner';

interface PartialRouteWithGuardsProps extends RouteProps {
    guards: (() => Promise<boolean>)[];
    onGuardFail?(): Promise<JSX.Element | null> | JSX.Element | null;
}

export type RouteWithGuardsProps = PropsWithChildren<PartialRouteWithGuardsProps>;

export const RouteWithGuards: React.FunctionComponent<RouteWithGuardsProps> = (props: RouteWithGuardsProps): JSX.Element => {
    const [isLoading, setIsLoading] = React.useState(true);

    const { guards, onGuardFail, render, children } = props;
    const Component = props.component;

    const renderOverride = (routeComponentProps: RouteComponentProps): React.ReactNode => {
        const guardResolution = new Promise<JSX.Element | null>(
            async (resolve): Promise<void> => {
                let guardsPassed = false;

                if (guards) {
                    guardsPassed = (await Promise.all(guards.map((guard): Promise<boolean> => guard().catch((): boolean => false)))).every(
                        (guardPassed: boolean): boolean => guardPassed === true
                    );
                }

                if (guardsPassed) {
                    setIsLoading(false);
                    if (children) {
                        resolve(<>{children}</>);
                    } else if (render) {
                        resolve(render(routeComponentProps) as JSX.Element);
                    } else if (Component) {
                        resolve(<Component {...routeComponentProps} />);
                    }
                } else if (onGuardFail) {
                    setIsLoading(false);
                    resolve(onGuardFail());
                }

                resolve(null);
            }
        );

        return (
            <Condition if={!isLoading} else={<RouteGuardSpinner />}>
                <AsyncRenderer promise={guardResolution} />
            </Condition>
        );
    };

    const propsOverride: RouteProps = { ...props, render: renderOverride, component: undefined, children: undefined };
    delete propsOverride['children'];

    return <Route {...propsOverride} />;
};

import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import {
    ChoiceGroup,
    IChoiceGroupOption,
    DefaultButton,
    PrimaryButton,
    IStackProps,
    Stack,
    StackItem,
    ITag,
    IPickerItemProps,
    TagPicker,
    Label,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    Text,
    TextField,
    IconButton,
    IStackStyles,
    IStackTokens,
    IIconStyles,
    Dropdown,
    IDropdownOption,
    ResponsiveMode,
    IDropdownStyles,
    Callout,
    ITextFieldProps
} from 'office-ui-fabric-react';
import { FocusTrapCallout } from '@fluentui/react/lib/Callout';
import { FocusZone, FocusZoneTabbableElements } from '@fluentui/react/lib/FocusZone';

import { es } from '@gta/core';

import * as errorCodes from './../../shared/validators/errorCodes';
import FilePicker, { FileEvent } from '../FilePicker/FilePicker';
import { JobDetails, ReferFormModel, ExtendedOption, ExtendedChoiceGroupOption, ReferDetails } from '../../environment/interfaces';
import { endorsementChoices, locations, profiles, referFormValidtionDescriptor, relations, univesityCandidateChoices } from './referModel';
import { ReferEndorse, ReferUniversity } from '../../environment/contracts';
import Section from '../Section/Section';
import { TagPickerItem } from '../TagPickerItem/TagPickerItem';
import { triggerSurvey } from '../../environment/services/floodgate-survey';
import { useFormValidation } from '../../framework/form/validation';

import '../../../src/containers/App/App.css';
import styles from './ReferForm.module.css';
import { config } from '../../environment';
import { endoreChoiceGroupStyles } from './ReferFormStyles';

const dropdownStyles: Partial<IDropdownStyles> = {
    callout: {
        maxWidth: '430px'
    }
};

type UniversityStudentGroupChangeHandler = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => void;

export interface ReferFormProps extends Partial<RouteComponentProps> {
    onJobSearch?(jobId: string): Promise<JobDetails | null>;
    onReferAgain?(referId: string): Promise<ReferDetails | null>;
    onSubmitForm?(referForm: ReferFormModel): Promise<void>;
    isFormSubmitting?: boolean;
    isReferDetailsLoading?: boolean;
    showNotificationBar?: boolean;
    onDismissNotificationBar?(): void;
}

const NoJobFound = 'No job found.';
const removeButtonAriaLabelPrefix = 'Remove selection for job ';

const stackColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 20 },
    styles: { root: { width: '100%' } }
};

const stackRowProps: Partial<IStackProps> = {
    tokens: { childrenGap: 20 },
    styles: { root: { justifyContent: 'flex-end' } }
};

const stackTokens: IStackTokens = {
    childrenGap: 4,
    maxWidth: 300
};

const labelCalloutStackStyles: Partial<IStackStyles> = { root: { padding: 20 } };
const iconStyles: Partial<IIconStyles> = { root: { marginBottom: -3, top: 4 } };
const closeBtnSyles: Partial<IIconStyles> = { root: { marginLeft: 180 } };

const iconProps = { iconName: 'Info' };

export const ReferForm: React.FunctionComponent<ReferFormProps> = (props: ReferFormProps): JSX.Element => {
    const formValidator = useFormValidation(referFormValidtionDescriptor);
    const tagPickerRef = React.useRef<HTMLDivElement>(null);
    const [tagPickerError, setTagPickerError] = React.useState<boolean>(false);
    const [clearReferForm, setClearReferForm] = React.useState<boolean>(false);
    const history = useHistory();

    const getAndSetDefaultJob = async (): Promise<JobDetails | null> => {
        if (!props.location) {
            return null;
        }

        const params = es.parseQueryString(props.location.search);
        if (!(props.onJobSearch && params && params.jobId)) {
            return null;
        }

        const job = await props.onJobSearch(params.jobId as string);
        if (job) {
            const jobTag: ITag = { key: job.jobId, name: `(${job.jobId}) ${job.jobRoleName}` };
            formValidator.validate('jobs', [...formValidator.form.jobs.value, jobTag]);
        }
        return job;
    };

    const handleTagPicker = (): void => {
        if (tagPickerRef && tagPickerRef.current) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tagInput = (tagPickerRef.current as any).input.current;
            if (tagInput.value) {
                tagInput._inputElement.current.offsetParent.style = 'border: 1px solid rgb(164, 38, 44);';
                return setTagPickerError(true);
            }
            tagInput._inputElement.current.offsetParent.style = '';
            return setTagPickerError(false);
        }
    };

    const getErrorMessage = (controlName: keyof ReferFormModel): string | undefined => {
        const control = formValidator.form[controlName];
        if (control && control.dirty && control.errors) {
            const errorMessages: string[] = [];
            for (const errorCode in control.errors) {
                errorMessages.push(control.errors[errorCode]);
            }
            return errorMessages.join(' ');
        }
    };

    const resumeSectionValidator = (): void => {
        const requiredResumeOrLinkedinError = { ['required']: errorCodes.requiredResumeOrLinkedinUrl };
        formValidator.form.resumeFile.valid = false;
        formValidator.form.linkedInURL.valid = false;

        if (!formValidator.form.resumeFile.value) {
            formValidator.form.resumeFile.errors = !formValidator.form.linkedInURL.errors ? null : requiredResumeOrLinkedinError;
        }

        if (!formValidator.form.linkedInURL.value) {
            formValidator.form.linkedInURL.errors = !formValidator.form.resumeFile.errors ? null : requiredResumeOrLinkedinError;
        }

        if (!formValidator.form.resumeFile.errors) {
            formValidator.form.resumeFile.errors = null;
            formValidator.form.resumeFile.valid = true;
        }

        if (!formValidator.form.linkedInURL.errors) {
            formValidator.form.linkedInURL.errors = null;
            formValidator.form.linkedInURL.valid = true;
        }
    };

    const dismissMessageBar = (): void => {
        if (props.onDismissNotificationBar) {
            props.onDismissNotificationBar();
        }
    };

    const onResumeFileChange = (fileEvent: FileEvent, isResumeAlreadyUploaded: boolean = false): void => {
        if (fileEvent && fileEvent.event && !isResumeAlreadyUploaded) {
            formValidator.validate('resumeUri' as keyof ReferFormModel, ``);
            fileEvent.event.stopPropagation();
            const file =
                fileEvent.event.target.files && fileEvent.event.target.files.length > 0 && fileEvent.event.target.files[0]
                    ? fileEvent.event.target.files[0]
                    : null;
            formValidator.validate('resumeFile' as keyof ReferFormModel, file);
            if (fileEvent.errorMessage) {
                formValidator.form.resumeFile.errors = { ['file']: fileEvent.errorMessage };
            }
        } else if (fileEvent && isResumeAlreadyUploaded) {
            const file = new File([], fileEvent.fileDispayNames[0]);
            formValidator.validate('resumeFile' as keyof ReferFormModel, file);
        }
        resumeSectionValidator();
    };

    const getAndSetReferDetails = async (): Promise<ReferDetails | null> => {
        if (!props.location) {
            return null;
        }

        const params = es.parseQueryString(props.location.search);

        if (!(props.onReferAgain && params && params.referId)) {
            return null;
        }

        const referDetails = await props.onReferAgain(params.referId as string);
        if (referDetails) {
            formValidator.validate('firstName', referDetails.firstName);
            formValidator.validate('lastName', referDetails.lastName);
            formValidator.validate('candidateEmail', referDetails.candidateEmail);
            formValidator.validate('candidatePhone', referDetails.candidatePhone);
            formValidator.validate('location', referDetails.location);
            formValidator.validate('acquaintanceLevel', referDetails.acquaintanceLevel);
            formValidator.validate('isEndorsed', referDetails.isEndorsed);
            formValidator.validate('isUniversityStudent', referDetails.isUniversityStudent);
            formValidator.validate('additionalInformation', referDetails.additionalInformation);

            if (referDetails.linkedInURL) {
                formValidator.validate('linkedInURL', referDetails.linkedInURL);
            }
            if (referDetails.resumeFileType && referDetails.resumeUri) {
                formValidator.validate('resumeUri', referDetails.resumeUri);
                onResumeFileChange({ fileDispayNames: [referDetails.resumeFileType], errorMessage: '', event: undefined }, true);
            }
            resumeSectionValidator();
        }
        return referDetails;
    };

    const getFileDisplayName = (): string[] => {
        const fileDisplayNames: string[] = [];
        if (!formValidator.form.resumeFile.value) {
            return fileDisplayNames;
        }

        if (!formValidator.form.resumeUri.value) {
            const fileSizeInKB = (formValidator.form.resumeFile.value.size / 1024).toFixed(2);
            fileDisplayNames.push(formValidator.form.resumeFile.value.name + ' (' + fileSizeInKB + ' KB)');
        } else {
            fileDisplayNames.push(formValidator.form.resumeFile.value.name);
        }

        return fileDisplayNames;
    };

    useEffect((): void => {
        getAndSetDefaultJob();
        getAndSetReferDetails();
        document.title = 'Microsoft Referrals';
    }, []);

    const onTextFieldValueChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        event.stopPropagation();
        const referFormKeyName = (event.target as HTMLInputElement).name as keyof ReferFormModel;
        formValidator.validate(referFormKeyName, newValue);
        if (referFormKeyName === 'linkedInURL') {
            resumeSectionValidator();
        }
    };

    const onClearSelectedFiles = (): void => {
        formValidator.validate('resumeFile' as keyof ReferFormModel, null);
        formValidator.validate('resumeUri' as keyof ReferFormModel, ``);
    };

    const getTextFromItem = (item: ITag): string => String(item.key);

    const onChoiceChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (event && option) {
            event.stopPropagation();
            const optionWithPayload = option as ExtendedChoiceGroupOption<string>;
            formValidator.validate((event.target as HTMLInputElement).name as keyof ReferFormModel, optionWithPayload.payload);
        }
    };

    const getKeyFromPayload = (
        options: ExtendedChoiceGroupOption<ReferEndorse | ReferUniversity | boolean>[],
        name: keyof ReferFormModel
    ): string | number | undefined => {
        const choice = options.find((a): boolean => a.payload === formValidator.form[name].value);
        if (choice) {
            return choice.key;
        }
    };

    const onUniversityStudentGroupChange = (name: keyof ReferFormModel): UniversityStudentGroupChangeHandler => (
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void => {
        if (event && option) {
            event.stopPropagation();
            const optionWithPayload = option as ExtendedChoiceGroupOption<string>;
            formValidator.validate(name as keyof ReferFormModel, optionWithPayload.payload);
            formValidator.validate('isUniversityStudent' as keyof ReferFormModel, formValidator.form.isUniversityStudent.value);
        }
    };

    const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        if (option) {
            const optionWithPayload = option as ExtendedOption<string>;
            formValidator.validate(optionWithPayload.payload as keyof ReferFormModel, optionWithPayload.key);
        }
    };

    const listContainsTag = (key: string | number, tagList?: ITag[]): boolean => {
        if (!tagList || !tagList.length) {
            return false;
        }

        return (
            tagList.filter((compareTag: ITag): boolean =>
                typeof compareTag.key === 'string' && typeof key === 'string'
                    ? compareTag.key.toLowerCase() === key.toLowerCase()
                    : compareTag.key === key
            ).length > 0
        );
    };

    const onSelectedJobsChange = (selectedJobs?: ITag[]): void => {
        const filteredJobs: ITag[] = [];
        if (selectedJobs) {
            for (const job of selectedJobs) {
                if (!listContainsTag(job.key, filteredJobs)) {
                    filteredJobs.push(job);
                }
            }
        }
        formValidator.validate('jobs' as keyof ReferFormModel, filteredJobs);
    };

    const onFilterChanged = async (jobId?: string): Promise<ITag[] | []> => {
        if (isNaN(Number(jobId))) {
            setTagPickerError(true);
            return [];
        }
        if (!(jobId && jobId.trim() && props.onJobSearch)) {
            setTagPickerError(false);
            return [];
        }

        const job = await props.onJobSearch(jobId.trim());
        if (job) {
            setTagPickerError(false);
            const jobTag: ITag[] = [{ key: job.jobId, name: `(${job.jobId}) ${job.jobRoleName}` }];
            return jobTag;
        }

        return [];
    };

    const removeUrlParameter = (paramKey: string): void => {
        let href = window.location.href;
        var url = new URL(href);
        url.searchParams.delete(paramKey);
        history.replace({
            search: url.searchParams.toString()
        });
    };

    const clearFormControlsAndUpdateUrl = (): void => {
        formValidator.reset();
        if (props && props.location && props.location.search) {
            let urlParams = new URLSearchParams(props.location.search);
            urlParams.delete('referId');
            if (urlParams.toString()) {
                props.location.search = `?${urlParams.toString()}`;
            } else {
                props.location.search = `${urlParams.toString()}`;
            }
            removeUrlParameter('referId');
        }
    };

    const onClearButtonClicked = (): void => {
        clearFormControlsAndUpdateUrl();
        setClearReferForm(true);
        setTimeout((): void => {
            setClearReferForm(false);
        }, 2500);
    };

    const onSubmitReferForm = async (): Promise<void> => {
        if (props.onSubmitForm) {
            const referFormModel = formValidator.toModel();
            await props.onSubmitForm(referFormModel as ReferFormModel);
        }
        if (!props.isFormSubmitting) {
            clearFormControlsAndUpdateUrl();
            /* Add delay to the survey trigger to allow the form submission message to read by screen reader */
            window.setTimeout((): void => {
                triggerSurvey('SurveyTriggerOnSubmitReferral');
            }, 4000);
        }
    };

    const [isCandidateEmailCalloutVisible, setIsCandidateEmailCalloutVisible] = React.useState<boolean>(false);
    const [isJobIdCalloutVisible, setIsJobIdCalloutVisible] = React.useState<boolean>(false);
    const [isProfessionCalloutVisible, setIsProfessionCalloutVisible] = React.useState<boolean>(false);
    const [isResumeCalloutVisible, setIsResumeCalloutVisible] = React.useState<boolean>(false);
    const [isCurrentUniversityStudentCalloutVisible, setIsCurrentUniversityStudentCalloutVisible] = React.useState<boolean>(false);
    const [isCampaignCodeCalloutVisible, setIsCampaignCodeCalloutVisible] = React.useState<boolean>(false);

    const toggleLabelCalloutVisibility = (callout: boolean, callOutStateFunction: React.Dispatch<React.SetStateAction<boolean>>): void => {
        callOutStateFunction(!callout);
    };

    const onRenderLabel = (props?: ITextFieldProps): JSX.Element => <CustomLabel {...props} required />;

    const CustomLabel = (props: ITextFieldProps): JSX.Element => {
        const descriptionId = 'description';
        const iconButtonId = 'iconButton';

        return (
            <>
                <Stack horizontal verticalAlign="center" tokens={stackTokens}>
                    <Label htmlFor={props.id}>{props.label} </Label>
                    <span className="required" />
                    <IconButton
                        id={iconButtonId}
                        iconProps={iconProps}
                        title="Candidate Email Address Information"
                        onClick={(): void => {
                            toggleLabelCalloutVisibility(isCandidateEmailCalloutVisible, setIsCandidateEmailCalloutVisible);
                        }}
                    />
                </Stack>
                {isCandidateEmailCalloutVisible && (
                    <Callout
                        target={'#' + iconButtonId}
                        setInitialFocus
                        onDismiss={(): void => {
                            toggleLabelCalloutVisibility(isCandidateEmailCalloutVisible, setIsCandidateEmailCalloutVisible);
                        }}
                        ariaDescribedBy={'description'}
                        role="dialog"
                    >
                        <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                            <Text block variant="xLarge" className={styles.callOutTitle} id="callout-label">
                                Candidate Email Address
                            </Text>
                            <span id={descriptionId}>
                                Please verify this email address is accurate or the candidate will not receive an email with the next steps
                                they need to complete in order to be considered by the recruiting team.
                            </span>
                            <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                <DefaultButton
                                    styles={closeBtnSyles}
                                    onClick={(): void => {
                                        toggleLabelCalloutVisibility(isCandidateEmailCalloutVisible, setIsCandidateEmailCalloutVisible);
                                    }}
                                >
                                    Close
                                </DefaultButton>
                            </FocusZone>
                        </Stack>
                    </Callout>
                )}
            </>
        );
    };

    return (
        <Stack {...stackColumnProps}>
            {props.isReferDetailsLoading && <Spinner ariaLabel="Getting refer details" size={SpinnerSize.large} />}

            <form className={styles.form}>
                <Section>
                    <TextField
                        autoComplete="off"
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('firstName')}
                        label="Candidate First Name:"
                        name="firstName"
                        className={styles.formTextField}
                        onChange={onTextFieldValueChange}
                        required
                        aria-required="true"
                        value={formValidator.form.firstName.value}
                    />
                    <TextField
                        autoComplete="off"
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('lastName')}
                        label="Candidate Last Name:"
                        className={styles.formTextField}
                        name="lastName"
                        onChange={onTextFieldValueChange}
                        required
                        aria-required="true"
                        value={formValidator.form.lastName.value}
                    />
                    <TextField
                        autoComplete="off"
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('candidateEmail')}
                        id="candidateEmail"
                        name="candidateEmail"
                        onChange={onTextFieldValueChange}
                        value={formValidator.form.candidateEmail.value}
                        onRenderLabel={onRenderLabel}
                        aria-required="true"
                        ariaLabel="Candidate Email Address*"
                        label="Candidate Email Address:"
                        required
                    />
                    <TextField
                        autoComplete="off"
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('candidatePhone')}
                        className={styles.formTextField}
                        label="Candidate Mobile Phone:"
                        name="candidatePhone"
                        onChange={onTextFieldValueChange}
                        value={formValidator.form.candidatePhone.value}
                    />
                    <Dropdown
                        styles={dropdownStyles}
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('location')}
                        label="Candidate Location:"
                        onChange={onDropdownChange}
                        options={locations}
                        required
                        selectedKey={formValidator.form.location.value}
                        responsiveMode={ResponsiveMode.large}
                    />
                    <Label htmlFor="searchForJobIDs">
                        What Job ID(s) are you referring your candidate to?
                        <IconButton
                            id="searchJobIdLabel"
                            iconProps={iconProps}
                            title="Job ID(s) Information"
                            onClick={(): void => {
                                toggleLabelCalloutVisibility(isJobIdCalloutVisible, setIsJobIdCalloutVisible);
                            }}
                            styles={iconStyles}
                        />
                        {isJobIdCalloutVisible && (
                            <FocusTrapCallout
                                target="#searchJobIdLabel"
                                setInitialFocus
                                onDismiss={(): void => {
                                    toggleLabelCalloutVisibility(isJobIdCalloutVisible, setIsJobIdCalloutVisible);
                                }}
                                ariaLabel="Job ID(s) Information"
                                ariaDescribedBy="searchJobIdLabeldescriptionId"
                                role="dialog"
                            >
                                <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                                    <Text block variant="xLarge" className={styles.callOutTitle} id="callout-label">
                                        Job ID(s)
                                    </Text>
                                    <span id="searchJobIdLabeldescriptionId">
                                        If the job doesn’t load once you have entered the job ID, the job is posted for internal employees
                                        only. External candidates cannot be referred to internal jobs.
                                    </span>
                                    <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                        <DefaultButton
                                            styles={closeBtnSyles}
                                            onClick={(): void => {
                                                toggleLabelCalloutVisibility(isJobIdCalloutVisible, setIsJobIdCalloutVisible);
                                            }}
                                        >
                                            Close
                                        </DefaultButton>
                                    </FocusZone>
                                </Stack>
                            </FocusTrapCallout>
                        )}
                    </Label>
                    <TagPicker
                        ref={tagPickerRef}
                        className={tagPickerError ? styles.tagPickerSpacerMargin : styles.tagPickerSpacer}
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        getTextFromItem={getTextFromItem}
                        inputProps={{ 'aria-label': 'What Job ID(s) are you referring your candidate to?', id: 'searchForJobIDs' }}
                        onChange={onSelectedJobsChange}
                        onRenderItem={(props: IPickerItemProps<ITag>): JSX.Element => (
                            <TagPickerItem {...props} removeButtonAriaLabel={removeButtonAriaLabelPrefix + props.item.name} />
                        )}
                        resolveDelay={500}
                        onResolveSuggestions={onFilterChanged}
                        pickerSuggestionsProps={{ noResultsFoundText: NoJobFound }}
                        selectedItems={formValidator.form.jobs.value}
                        onBlur={handleTagPicker}
                    />
                    {tagPickerError && (
                        <div role="alert">
                            <p className={styles.tagError}>
                                <span>{errorCodes.inCorrectJobID}</span>
                            </p>
                        </div>
                    )}
                    <Label htmlFor="selectProfessionOrCarrerField-input">
                        What Profession/Career field are you referring the candidate for? <span className="required" />
                        <IconButton
                            id="selectProfessionLabel"
                            iconProps={iconProps}
                            title="Profession/Career Field Information"
                            onClick={(): void => {
                                toggleLabelCalloutVisibility(isProfessionCalloutVisible, setIsProfessionCalloutVisible);
                            }}
                            styles={{ root: { marginBottom: -3, top: 1 } }}
                        />
                        {isProfessionCalloutVisible && (
                            <FocusTrapCallout
                                target="#selectProfessionLabel"
                                setInitialFocus
                                onDismiss={(): void => {
                                    toggleLabelCalloutVisibility(isProfessionCalloutVisible, setIsProfessionCalloutVisible);
                                }}
                                ariaLabel="Profession/Career Field Information"
                                ariaDescribedBy="selectProfessionLabeldescriptionId"
                                role="dialog"
                            >
                                <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                                    <Text block variant="xLarge" className={styles.callOutTitle} id="callout-label">
                                        Profession/Career Field
                                    </Text>
                                    <span id="selectProfessionLabeldescriptionId">
                                        Profession/Career Field would be Software Engineering, Program Management, Technical Support, etc.
                                        Select the most applicable option.
                                    </span>
                                    <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                        <DefaultButton
                                            styles={closeBtnSyles}
                                            onClick={(): void => {
                                                toggleLabelCalloutVisibility(isProfessionCalloutVisible, setIsProfessionCalloutVisible);
                                            }}
                                        >
                                            Close
                                        </DefaultButton>
                                    </FocusZone>
                                </Stack>
                            </FocusTrapCallout>
                        )}
                    </Label>
                    <Dropdown
                        aria-label="What Profession/Career field are you referring the candidate for?*"
                        styles={dropdownStyles}
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('profile')}
                        onChange={onDropdownChange}
                        id="selectProfessionOrCarrerField"
                        options={profiles}
                        selectedKey={formValidator.form.profile.value}
                        responsiveMode={ResponsiveMode.large}
                        aria-required="true"
                    />
                </Section>

                <Section
                    ariaLabel="Resume required"
                    title={
                        <>
                            Resume <span className="required" />
                            <IconButton
                                id="linkedinLabel"
                                iconProps={iconProps}
                                title="Resume Information"
                                onClick={(): void => {
                                    toggleLabelCalloutVisibility(isResumeCalloutVisible, setIsResumeCalloutVisible);
                                }}
                                styles={{ root: { marginBottom: -3, top: 1 } }}
                            />
                            {isResumeCalloutVisible && (
                                <FocusTrapCallout
                                    target="#linkedinLabel"
                                    setInitialFocus
                                    onDismiss={(): void => {
                                        toggleLabelCalloutVisibility(isResumeCalloutVisible, setIsResumeCalloutVisible);
                                    }}
                                    ariaLabel="Resume Information"
                                    ariaDescribedBy="linkedinLabelCalloutDescriptionId"
                                    role="dialog"
                                >
                                    <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                                        <Text block variant="xLarge" className={styles.callOutTitle} id="callout-label">
                                            Resume
                                        </Text>
                                        <span id="linkedinLabelCalloutDescriptionId">
                                            Please provide if you are not referring your candidate for a specific job(s).
                                        </span>
                                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                            <DefaultButton
                                                styles={closeBtnSyles}
                                                onClick={(): void => {
                                                    toggleLabelCalloutVisibility(isResumeCalloutVisible, setIsResumeCalloutVisible);
                                                }}
                                            >
                                                Close
                                            </DefaultButton>
                                        </FocusZone>
                                    </Stack>
                                </FocusTrapCallout>
                            )}
                        </>
                    }
                >
                    <Text className={styles.resumeDetails}>
                        Either upload the candidate&apos;s resume or provide a link to their LinkedIn profile.
                    </Text>
                    <FilePicker
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        fileNames={getFileDisplayName()}
                        onChange={onResumeFileChange}
                        buttonText="Browse"
                        acceptedFileExtensions={['doc', 'docx', 'pdf', 'htm', 'html', 'txt', 'odt', 'tif', 'tiff', 'rtf']}
                        errorMessage={getErrorMessage('resumeFile')}
                        maxFileNameLength={90}
                        allowedFileSizeInMB={5}
                        label="Select the resume you want to use. Acceptable file formats are ( .doc, .docx, .pdf, .htm, .html, .txt, .odt, .tif, .tiff, .rtf ) and the max file size is 5 MB."
                        onClearSelectedFiles={onClearSelectedFiles}
                    />
                    <Text as="p" className={styles.resumeOrLinkedIn}>
                        OR
                    </Text>
                    <Label htmlFor="linkedInURL">
                        <span className="visually-hidden">LinkedIn</span>
                        <img alt="LinkedIn" src={`${config.clientEndpoint}/assets/images/linkedin/linkedin.png`} /> URL:
                    </Label>
                    <TextField
                        autoComplete="off"
                        className={styles.resumeDetails}
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('linkedInURL')}
                        id="linkedInURL"
                        onChange={onTextFieldValueChange}
                        name="linkedInURL"
                        value={formValidator.form.linkedInURL.value}
                    />
                </Section>

                <Section ariaLabel="Additional Information" title="Additional Information">
                    <Dropdown
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('acquaintanceLevel')}
                        label="How do you know this person?"
                        onChange={onDropdownChange}
                        options={relations}
                        required
                        selectedKey={formValidator.form.acquaintanceLevel.value}
                        responsiveMode={ResponsiveMode.large}
                    />
                    <ChoiceGroup
                        label="Do you endorse this person professionally and recommend them as a hire?"
                        name="isEndorsed"
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        options={endorsementChoices}
                        onChange={onChoiceChange}
                        required
                        styles={endoreChoiceGroupStyles}
                        selectedKey={getKeyFromPayload(endorsementChoices, 'isEndorsed')}
                    />
                    <div>
                        <Label htmlFor="isUniversityStudent">
                            Is your referral a current university student or recent graduate? <span className="required" />
                            <IconButton
                                id="isUniversityStudentLabel"
                                iconProps={iconProps}
                                title="Recent Graduate Information"
                                onClick={(): void => {
                                    toggleLabelCalloutVisibility(
                                        isCurrentUniversityStudentCalloutVisible,
                                        setIsCurrentUniversityStudentCalloutVisible
                                    );
                                }}
                                styles={{ root: { marginBottom: -3, top: 1 } }}
                            />
                            {isCurrentUniversityStudentCalloutVisible && (
                                <FocusTrapCallout
                                    target="#isUniversityStudentLabel"
                                    setInitialFocus
                                    onDismiss={(): void => {
                                        toggleLabelCalloutVisibility(
                                            isCurrentUniversityStudentCalloutVisible,
                                            setIsCurrentUniversityStudentCalloutVisible
                                        );
                                    }}
                                    ariaLabel="Recent Graduate Information"
                                    ariaDescribedBy="isUniversityStudentLabelCalloutDescriptionId"
                                    role="dialog"
                                >
                                    <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                                        <Text block variant="xLarge" className={styles.callOutTitle} id="callout-label">
                                            Recent Graduate
                                        </Text>
                                        <span id="isUniversityStudentLabelDescriptionId">
                                            A recent graduate is a candidate that graduated within the last 12 months.
                                        </span>
                                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                            <DefaultButton
                                                styles={closeBtnSyles}
                                                onClick={(): void => {
                                                    toggleLabelCalloutVisibility(
                                                        isCurrentUniversityStudentCalloutVisible,
                                                        setIsCurrentUniversityStudentCalloutVisible
                                                    );
                                                }}
                                            >
                                                Close
                                            </DefaultButton>
                                        </FocusZone>
                                    </Stack>
                                </FocusTrapCallout>
                            )}
                        </Label>
                        <ChoiceGroup
                            name="isUniversityStudent"
                            id="isUniversityStudent"
                            disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                            options={univesityCandidateChoices}
                            onChange={onUniversityStudentGroupChange('isUniversityStudent')}
                            required
                            selectedKey={getKeyFromPayload(univesityCandidateChoices, 'isUniversityStudent')}
                        />
                    </div>
                    <TextField
                        autoComplete="off"
                        disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                        errorMessage={getErrorMessage('additionalInformation')}
                        label="Please provide any additional information regarding the candidate’s experience, skills, knowledge, etc. (maximum 2000 characters)"
                        className={styles.formTextField}
                        multiline={true}
                        name="additionalInformation"
                        onChange={onTextFieldValueChange}
                        value={formValidator.form.additionalInformation.value}
                    />
                    <div className={styles.formTextField}>
                        <Label htmlFor="campaignCode">
                            Referral Campaign Code
                            <IconButton
                                id="campaignCodeLabel"
                                iconProps={iconProps}
                                title="Referral Campaign Code Information"
                                onClick={(): void => {
                                    toggleLabelCalloutVisibility(isCampaignCodeCalloutVisible, setIsCampaignCodeCalloutVisible);
                                }}
                                styles={iconStyles}
                            />
                            {isCampaignCodeCalloutVisible && (
                                <FocusTrapCallout
                                    target="#campaignCodeLabel"
                                    setInitialFocus
                                    onDismiss={(): void => {
                                        toggleLabelCalloutVisibility(isCampaignCodeCalloutVisible, setIsCampaignCodeCalloutVisible);
                                    }}
                                    ariaLabel="Referral Campaign Code Information"
                                    ariaDescribedBy="campaignCodeLabelCalloutDescriptionId"
                                    role="dialog"
                                >
                                    <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                                        <Text block variant="xLarge" className={styles.callOutTitle} id="callout-label">
                                            Referral Campaign Code
                                        </Text>
                                        <span id="campaignCodeLabelCalloutDescriptionId">
                                            Only provide a referral code if your referral is a part of an enhanced bonus campaign.
                                        </span>
                                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                            <DefaultButton
                                                styles={closeBtnSyles}
                                                onClick={(): void => {
                                                    toggleLabelCalloutVisibility(
                                                        isCampaignCodeCalloutVisible,
                                                        setIsCampaignCodeCalloutVisible
                                                    );
                                                }}
                                            >
                                                Close
                                            </DefaultButton>
                                        </FocusZone>
                                    </Stack>
                                </FocusTrapCallout>
                            )}
                        </Label>
                        <TextField
                            autoComplete="off"
                            disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                            errorMessage={getErrorMessage('campaignCode')}
                            name="campaignCode"
                            id="campaignCode"
                            onChange={onTextFieldValueChange}
                            value={formValidator.form.campaignCode.value}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                        <Text as="p">
                            <a href="https://go.microsoft.com/fwlink/?linkid=518021" target="_blank" rel="noopener noreferrer">
                                Microsoft Data Privacy Notice (DPN)
                                <span className="visually-hidden">Opens in new window</span>
                            </a>
                            .
                        </Text>
                    </div>
                </Section>

                <Stack horizontal {...stackRowProps} className={styles.formActions} tokens={{ childrenGap: 10 }}>
                    <StackItem align="center" grow role="status">
                        {props.showNotificationBar && !clearReferForm && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                onDismiss={dismissMessageBar}
                                role="none"
                            >
                                Thank you! Your referral was submitted successfully.
                            </MessageBar>
                        )}
                        {clearReferForm && (
                            <MessageBar messageBarType={MessageBarType.info} isMultiline={false} onDismiss={dismissMessageBar} role="none">
                                Referral form cleared successfully.
                            </MessageBar>
                        )}
                    </StackItem>
                    {props.isFormSubmitting && (
                        <StackItem align="center">
                            <Spinner ariaLabel="Referral form is being submitted" size={SpinnerSize.large} />
                        </StackItem>
                    )}
                    <StackItem align="center">
                        <PrimaryButton
                            ariaLabel="Submit the referral form"
                            data-automation-id="Submit"
                            disabled={!formValidator.valid || props.isFormSubmitting || props.isReferDetailsLoading || tagPickerError}
                            onClick={onSubmitReferForm}
                            text="Submit"
                        />
                    </StackItem>
                    <StackItem align="center">
                        <DefaultButton
                            ariaLabel="Clear the referral form"
                            data-automation-id="Clear"
                            onClick={onClearButtonClicked}
                            disabled={props.isFormSubmitting || props.isReferDetailsLoading}
                            text="Clear"
                        />
                    </StackItem>
                </Stack>
            </form>
        </Stack>
    );
};

export default ReferForm;

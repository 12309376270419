import { useState, useRef, useEffect } from 'react';

import { ErrorService, ErrorCodeDetails } from '@gta/core';

import { errorCodePropertyName } from '../components/ErrorServiceDialog/ErrorServiceDialog';
import { CommonErrorDetails, ErrorDetails, ReportErrorDetails } from '../components/ErrorDialog/ErrorDialog';

export type UseErrorServiceReturnValue = [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
    React.MutableRefObject<ErrorDetails | null>
];

export interface UseErrorServiceParams {
    errorService: ErrorService;
    getReportErrorDetails(errorDetails: CommonErrorDetails): ReportErrorDetails;
    getStaticProperties(): Record<string, string>;
}

export const useErrorService = (useErrorServiceParams: UseErrorServiceParams): UseErrorServiceReturnValue => {
    const [isErrorDialogHidden, setIsErrorDialogHidden] = useState<boolean>(true);
    const errorRef = useRef<ErrorDetails | null>(null);

    useEffect(
        (): (() => void) =>
            useErrorServiceParams.errorService.registerErrorHandler((error: ErrorCodeDetails): void => {
                const commonErrorProperties: CommonErrorDetails = {
                    title: error.title,
                    details: { [errorCodePropertyName]: error.code, ...error.payload, ...useErrorServiceParams.getStaticProperties() },
                    message: error.message
                };

                if (error.isFatal) {
                    errorRef.current = {
                        ...commonErrorProperties,
                        type: 'fatal',
                        reportDetails: useErrorServiceParams.getReportErrorDetails(commonErrorProperties)
                    };
                } else {
                    errorRef.current = { ...commonErrorProperties, type: 'benign' };
                }

                setIsErrorDialogHidden(false);
            }),
        [useErrorServiceParams.errorService]
    );

    return [isErrorDialogHidden, setIsErrorDialogHidden, errorRef];
};

import { combineReducers } from 'redux';

import consentReducer, { CandidateConsentStoreState } from './consent/consent';
import historyReducer, { ReferHistoryStoreState } from './history/history';
import referFormReducer, { ReferFormStoreState } from './refer/refer';
import feedbackReducer, { InterviewFeedbackStoreState } from './ur/feedback/feedback';
import dynFeedbackPrivilegeReducer, { DynFeedbackPrivilegeStoreState } from './ur/dynFeedbackPrivilege/dynFeedbackPrivilege';
import metadataReducer, { MetadataStoreState } from './ur/metadata/metadata';

export interface ReferAppStoreState {
    candidateConsent: CandidateConsentStoreState;
    history: ReferHistoryStoreState;
    referForm: ReferFormStoreState;
    interviewFeedback: InterviewFeedbackStoreState;
    metadata: MetadataStoreState;
    dynFeedbackPrivilege: DynFeedbackPrivilegeStoreState;
}

export default combineReducers({
    candidateConsent: consentReducer,
    history: historyReducer,
    referForm: referFormReducer,
    interviewFeedback: feedbackReducer,
    metadata: metadataReducer,
    dynFeedbackPrivilege: dynFeedbackPrivilegeReducer
});

import React from 'react';
import { IPickerItemProps, ITag, TagItem } from 'office-ui-fabric-react';

export const TagPickerItem: React.FunctionComponent<IPickerItemProps<ITag>> = (props: IPickerItemProps<ITag>): JSX.Element => {
    return (
        <TagItem {...props} removeButtonAriaLabel={props.removeButtonAriaLabel || props.item.name}>
            {props.item.name}
        </TagItem>
    );
};

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { TimedTelemetryEvent } from '@gta/core';

import * as ActionTypes from './actionTypes';
import { config } from '.././../../environment';
import { JobApplicationDetail } from '../../../environment/interfaces/JobApplicationDetail';
import { PreviousRefer } from '../../../environment/contracts/PreviousRefer';
import { ReferHistoryDetail } from '../../../environment/interfaces/ReferHistoryDetail';
import ReferStatus from '../../../environment/contracts/ReferStatus';
import { services } from '.././../../environment';
import { executeWithTelemetry } from '../../../environment/services/telemetry';

export interface GetReferralHistoryAction extends Action<typeof ActionTypes.GET_REFERRAL_HISTORY> {
    historyDetails?: ReferHistoryDetail[];
    isLoading: boolean;
}

const getReferralHistory = (isLoading: boolean, historyDetails?: ReferHistoryDetail[]): GetReferralHistoryAction => ({
    type: ActionTypes.GET_REFERRAL_HISTORY,
    historyDetails,
    isLoading
});

const getRoleFromReferrals = (jobDetails: JobApplicationDetail[]): string[] => {
    const referRoles: string[] = jobDetails.map((record): string => (record.jobRoleName ? `${record.jobRoleName} (${record.jobID})` : ''));
    return referRoles;
};

const referDisplayStatus = (status: ReferStatus): string => {
    switch (status) {
        case ReferStatus.ReferralSubmitted:
            return 'Referral submitted';
        case ReferStatus.SyncedToExternalSystem:
            return 'Engaged';
        case ReferStatus.Declined:
            return 'Referred by another Microsoft employee within the last 6 months';
        case ReferStatus.PendingCandidateResponse:
            return 'Pending candidate response';
        case ReferStatus.Submitted:
            return 'Engaged';
        case ReferStatus.Hired:
            return 'Hired';
        case ReferStatus.Active:
            return 'Added to candidate database';
        case ReferStatus.Inactive:
            return 'Referral pay-out period expired, candidate still in database';
        case ReferStatus.Ineligible:
            return 'Ineligible for referrals';
        case ReferStatus.NotConsented:
            return 'The candidate has not chosen to share the status of the referral with you';
        case ReferStatus.Apply:
        case ReferStatus.Screen:
        case ReferStatus.InterviewRequested:
            return 'Application submitted and being reviewed';
        case ReferStatus.InterviewScheduling:
            return 'Interviews are being scheduled';
        case ReferStatus.InterviewFeedback:
        case ReferStatus.Offer:
            return 'Interviews underway';
        case ReferStatus.OfferExtend:
            return 'Offer extended';
        case ReferStatus.OfferAccepted:
        case ReferStatus.PreOnboard:
            return 'Offer accepted';
        default:
            return '';
    }
};

const getStatusFromReferrals = (jobDetails: JobApplicationDetail[]): string[] => {
    const referStatuses: string[] = jobDetails.map((record): string => referDisplayStatus(record.jobReferStatus));
    return referStatuses;
};

export const getReferralHistoryThunk = (): ThunkAction<Promise<void>, void, void, GetReferralHistoryAction> => async (
    dispatch: ThunkDispatch<void, void, GetReferralHistoryAction>
): Promise<void> =>
    executeWithTelemetry(
        'GetReferralHistory',
        async (event: TimedTelemetryEvent): Promise<void> => {
            dispatch(getReferralHistory(true));
            let historyDetailItems: ReferHistoryDetail[] = [];
            const response = await services.http.get(`${config.serviceEndpoint}/v2/referhistory`).catch((): void => event.reject());
            event.resolve();

            if (response) {
                const previousReferrals: PreviousRefer[] = await response.body.json();
                if (previousReferrals && previousReferrals.length > 0) {
                    historyDetailItems = previousReferrals.map(
                        (record: PreviousRefer): ReferHistoryDetail => ({
                            referId: record.referID,
                            name: record.firstName + ' ' + record.lastName,
                            referredOn: record.referredDate.toString(),
                            roles: getRoleFromReferrals(record.jobDetails),
                            statuses: getStatusFromReferrals(record.jobDetails)
                        })
                    );
                }
            }

            dispatch(getReferralHistory(false, historyDetailItems));
        }
    );

export type Actions = GetReferralHistoryAction;

import { FormControlValidator, FormGroup } from '@gta/core';

import { ReferFormModel } from '../../environment/interfaces';

interface MaxLengthError {
    max: number;
    current: number;
}

export const maxLengthValidator = (
    controlValidator: FormControlValidator<string, ReferFormModel, MaxLengthError>
): FormControlValidator<string, ReferFormModel, string | null> => (value: string, form: FormGroup<ReferFormModel>): string | null => {
    const error = controlValidator(value, form);
    return error ? `Value must be less than ${error.max} characters. Current length: ${error.current}` : null;
};

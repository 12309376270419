export const profiles: { key: string; text: string }[] = [
    { key: 'Administration', text: 'Administration' },
    { key: 'Business Development and Strategy', text: 'Business Development and Strategy' },
    { key: 'Business Development & Ventures', text: 'Business Development & Ventures' },
    { key: 'Business Operations', text: 'Business Operations' },
    { key: 'Business Programs and Operations', text: 'Business Programs and Operations' },
    { key: 'Communications', text: 'Communications' },
    { key: 'Consulting Services', text: 'Consulting Services' },
    { key: 'Customer Success', text: 'Customer Success' },
    { key: 'Corporate Technology Support', text: 'Corporate Technology Support' },
    { key: 'Data Center', text: 'Data Center' },
    { key: 'Design & Creative', text: 'Design & Creative' },
    { key: 'Digital Sales and Solutions', text: 'Digital Sales and Solutions' },
    { key: 'Engineering', text: 'Engineering' },
    { key: 'Evangelism', text: 'Evangelism' },
    { key: 'Finance', text: 'Finance' },
    { key: 'General Management', text: 'General Management' },
    { key: 'Hardware Engineering', text: 'Hardware Engineering' },
    { key: 'Hardware Manufacturing Engineering', text: 'Hardware Manufacturing Engineering' },
    { key: 'Human Resources', text: 'Human Resources' },
    { key: 'IT Operations', text: 'IT Operations' },
    { key: 'Learning', text: 'Learning' },
    { key: 'Legal and Corporate Affairs', text: 'Legal and Corporate Affairs' },
    { key: 'Manufacturing', text: 'Manufacturing' },
    { key: 'Marketing', text: 'Marketing' },
    { key: 'Product Management', text: 'Product Management' },
    { key: 'Program Management', text: 'Program Management' },
    { key: 'Quantum Computing', text: 'Quantum Computing' },
    { key: 'Real Estate, Facilities, & Construction', text: 'Real Estate, Facilities, & Construction' },
    { key: 'Miscellaneous', text: 'Miscellaneous' },
    { key: 'Research', text: 'Research' },
    { key: 'Retail', text: 'Retail' },
    { key: 'Sales', text: 'Sales' },
    { key: 'Sales Enablement', text: 'Sales Enablement' },
    { key: 'Security Engineering', text: 'Security Engineering' },
    { key: 'Services', text: 'Services' },
    { key: 'Software Engineering', text: 'Software Engineering' },
    { key: 'Supply Chain', text: 'Supply Chain' },
    { key: 'Supply Chain and Operations Management', text: 'Supply Chain and Operations Management' },
    { key: 'Technical Sales', text: 'Technical Sales' },
    { key: 'Technical Support', text: 'Technical Support' }
];

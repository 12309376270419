import { errorService } from './error';
import { httpClientWithTelemetry } from './http';
import { logEvent, telemetryClient, executeWithTelemetry } from './telemetry';

export const services = {
    http: httpClientWithTelemetry,
    telemetry: {
        telemetryClient,
        logEvent,
        executeWithTelemetry
    },
    error: errorService
};

import { Actions } from '../../../actions/ur/feedback/feedback';
import * as ActionTypes from '../../../actions/ur/feedback/actionTypes';
import { DynamcisInterviewFeedback } from '../../../../environment/interfaces/DynamcisInterviewFeedback';

export interface InterviewFeedbackStoreState {
    feedbackDetails: DynamcisInterviewFeedback;
    isLoading: boolean;
}

const initialState: InterviewFeedbackStoreState = {
    feedbackDetails: {},
    isLoading: true
};

const reducer = (state: InterviewFeedbackStoreState = initialState, action: Actions): InterviewFeedbackStoreState => {
    switch (action.type) {
        case ActionTypes.SET_ISLOADING:
            return { ...state, isLoading: true };
        case ActionTypes.GET_INTERVIEW_FEEDBACK:
            return { ...state, feedbackDetails: action.feedbackDetails || initialState.feedbackDetails, isLoading: false };
        default:
            return state;
    }
};

export default reducer;

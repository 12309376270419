import { useEffect, useState } from 'react';

export interface AsyncRendererProps {
    promise: Promise<JSX.Element | null>;
}

export const AsyncRenderer: React.FunctionComponent<AsyncRendererProps> = (props: AsyncRendererProps): JSX.Element | null => {
    const [component, setComponent] = useState<JSX.Element | null>(null);
    let isUnmounted = false;

    useEffect((): (() => void) => {
        async function awaitComponent(): Promise<void> {
            if (props.promise) {
                const resolvedComponent = await props.promise;
                if (!isUnmounted) {
                    setComponent(resolvedComponent);
                }
            }
        }

        awaitComponent();

        return (): void => {
            isUnmounted = true;
        };
    });

    return component;
};

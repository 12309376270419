import * as OTel from '@microsoft/oteljs';
import { OneDSSink, OneDSEndpoint } from '@microsoft/oteljs-1ds';

export class SurveyTelemetrySinkProvider {
    private sink: OTel.TelemetrySink | undefined;

    // This is the callback that the Floodgate SDK will call to get the TelemetrySink.
    public getTelemetrySink(): OTel.TelemetrySink {
        if (!this.sink) {
            this.sink = this.createSink();
        }

        return this.sink;
    }

    // Reference this wiki page for OneDSSink creation,
    // the TelemetryLogger is not needed because FG SDK implements it internally: https://www.owiki.ms/wiki/Telemetry/OTelJS#1DS_sink
    private createSink(isEUDBUser = false): OTel.TelemetrySink {
        // Setting the required persistent data fields. Host sets User, App and Session fields for themselves.
        const persistentDataFields: OTel.DataField[] = [
            ...OTel.User.getFields({
                isAnonymous: true
            }),
            ...OTel.App.getFields({
                name: 'Refer',
                platform: 'Web',
                version: '0.1.0'
            })
        ];

        // For EUDB users, use OneDSEndpoint.EUDB. The host app has the context on which to use and has to set it appropriately.
        const oneDsSink = isEUDBUser
            ? new OneDSSink(persistentDataFields, { endpointUrl: OneDSEndpoint.EUDB })
            : new OneDSSink(persistentDataFields, { endpointUrl: OneDSEndpoint.PUBLIC });

        return oneDsSink;
    }
}

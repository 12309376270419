import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';

import { dom, es } from '@gta/core';
import { services, config } from '../../environment';
import { Login } from '../../components/Login/Login';
import ReferPivot from '../ReferPivot/ReferPivot';
import ConnectedCandidateConsent from '../ConnectedCandidateConsent/ConnectedCandidateConsent';
import ConnectedInterviewFeedback from '../UR/ConnectedInterviewFeedback/ConnectedInterviewFeedback';
import ConnectedFeedbackViewingPrivs from '../UR/ConnectedFeedbackViewingPrivs/ConnectedFeedbackViewingPrivs';
import ConnectedInterviewFeedbackDashboard from '../UR/ConnectedInterviewFeedbackDashboard/ConnectedInterviewFeedbackDashboard';
import { DefaultLayout } from '../../components/UR/DefaultLayout/DefaultLayout';
import { ErrorServiceDialog, errorCodePropertyName, ReportErrorDetails, CommonErrorDetails } from '../../framework/error';
import {
    urBaseRoute,
    urIVFeedbackDashboardRoute,
    urIVFeedbackViewingPrivilegesRoute,
    urIVFeedbackViewRoute
} from '../../environment/constants/ur';

import './App.css';
import { RouteWithGuards } from '../../framework/router';
import { createMsalGuard } from '../../framework/utilities/msalGuard';
import { MsalProvider } from '@azure/msal-react';
import { initOCVFloodGate } from '../../environment/services/floodgate-survey';
import { ReferralContainer } from '../ReferralContainer';
import { ChangeLanguage } from '../../framework/utilities/i18n.config';

interface AppProps {
    pca: IPublicClientApplication;
}

export const App: React.FC<AppProps> = ({ pca }: AppProps): JSX.Element => {
    const getStaticProperties = (): Record<string, string> => ({
        Application: config.application,
        Build: config.build.id,
        Environment: config.environment.toUpperCase(),
        'Service endpoint': config.serviceEndpoint,
        Session: config.sessionId,
        User: config.userProfile.objectId || ''
    });

    const getEmailBodyFromErrors = (errorDetails: Record<string, string>): string =>
        Object.keys(errorDetails)
            .map((key: string): string => `${key}: ${errorDetails[key]}`)
            .join('%0A');

    const getReportErrorDetails = (error: CommonErrorDetails): ReportErrorDetails => ({
        body:
            `Please include any details of the error below:` +
            `%0A%0A%0A-----%0AError details:%0A%0A${getEmailBodyFromErrors(error.details)}%0A%0A`,
        cc: '',
        subject: `Customer Reported Incident - ${config.application} - ${error.details[errorCodePropertyName]}`,
        to: 'gtasrceng@microsoft.com'
    });

    useEffect((): void => {
        initOCVFloodGate();
        const event = new CustomEvent('ReferralSpinnerVisibility', {});
        window.dispatchEvent(event);
    });

    useEffect((): (() => void) => {
        const handleLang = (e: CustomEvent<{ lang: string }>): void => {
            ChangeLanguage(e.detail.lang);
        };

        window.addEventListener('referralLanguage', handleLang as EventListener);
        return (): void => {
            window.removeEventListener('referralLanguage', handleLang as EventListener);
        };
    }, []);

    return (
        <MsalProvider instance={pca}>
            <main>
                <BrowserRouter>
                    <Switch>
                        <Redirect
                            exact
                            from="/"
                            to={
                                /* eslint-disable prettier/prettier */
                                window.location.hostname.toLowerCase().indexOf('ur') > -1
                                    ? urIVFeedbackDashboardRoute
                                    : window.location.pathname.includes('/referrals/login') // eslint-disable-next-line @typescript-eslint/indent
                                    ? '/referrals' // eslint-disable-next-line @typescript-eslint/indent
                                    : '/widgets/refer'
                                /* eslint-enable prettier/prettier */
                            }
                        />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/:country?/:lang?/referrals/login" component={Login} />
                        <RouteWithGuards
                            path="/:country?/:lang?/referrals"
                            guards={[createMsalGuard()]}
                            onGuardFail={(): JSX.Element => {
                                dom.setEntryRoute({
                                    url: window.location.pathname,
                                    queryParams: es.parseQueryString(window.location.search)
                                });
                                return <Redirect exact to="/:country?/:lang?/referrals/login" />;
                            }}
                        >
                            <Route exact path="/:country?/:lang?/referrals" component={ReferralContainer} />
                        </RouteWithGuards>
                        <Redirect exact from={urBaseRoute} to={urIVFeedbackDashboardRoute} />
                        <RouteWithGuards
                            path="/widgets"
                            guards={[createMsalGuard()]}
                            onGuardFail={(): JSX.Element => {
                                dom.setEntryRoute({
                                    url: window.location.pathname,
                                    queryParams: es.parseQueryString(window.location.search)
                                });
                                return <Redirect exact to="/login" />;
                            }}
                        >
                            <Route exact path="/widgets/refer" component={ReferPivot} />
                            <Route exact path={urIVFeedbackViewingPrivilegesRoute} component={ConnectedFeedbackViewingPrivs} />
                            <Route
                                exact
                                path={urIVFeedbackViewRoute}
                                render={(props): JSX.Element => (
                                    <DefaultLayout {...props} userProfile={config.userProfile}>
                                        <ConnectedInterviewFeedback {...props} />
                                    </DefaultLayout>
                                )}
                            />
                            <Route
                                exact
                                path={urIVFeedbackDashboardRoute}
                                render={(props): JSX.Element => (
                                    <DefaultLayout {...props} userProfile={config.userProfile}>
                                        <ConnectedInterviewFeedbackDashboard {...props} />
                                    </DefaultLayout>
                                )}
                            />
                        </RouteWithGuards>
                        <Route exact path="/consent/:referId/" component={ConnectedCandidateConsent} />
                        <Redirect exact from="/consent/:referId/:referreeName" to="/consent/:referId/" />
                    </Switch>
                </BrowserRouter>
            </main>

            <ErrorServiceDialog
                errorService={services.error}
                getStaticProperties={getStaticProperties}
                getReportErrorDetails={getReportErrorDetails}
            />
        </MsalProvider>
    );
};

import { useState, useRef, useEffect } from 'react';

import { createFormValidator, FormValidationDescriptor, FormValidator } from '@gta/core';

export function useFormValidation<TModel>(descriptor: FormValidationDescriptor<TModel>): FormValidator<TModel> {
    const formValidator = useRef(createFormValidator(descriptor));
    const [formValues, setFormValues] = useState<Partial<TModel>>({});

    useEffect((): (() => void) => {
        return formValidator.current.onValueChange(<T>(name: string | number | symbol, value: T): void =>
            setFormValues({ ...formValues, [name]: value })
        );
    }, [descriptor]);

    const reset = formValidator.current.reset;
    formValidator.current.reset = (): void => {
        reset();
        setFormValues({});
    };

    return formValidator.current;
}

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { TimedTelemetryEvent } from '@gta/core';

import * as ActionTypes from './actionTypes';
import { services, config } from '../../../../environment';
import { GraphUser } from '../../../../environment/interfaces/GraphUser';
import { executeWithTelemetry } from '../../../../environment/services/telemetry';

export interface GetPeopleAction extends Action<typeof ActionTypes.GET_PEOPLE> {
    searchedUsers?: GraphUser[];
}

const getPeople = (searchedUsers: GraphUser[]): GetPeopleAction => ({
    type: ActionTypes.GET_PEOPLE,
    searchedUsers
});

export const getPeopleThunk = (prefix: string, limit: number = 4): ThunkAction<Promise<GraphUser[]>, void, void, GetPeopleAction> => async (
    dispatch: ThunkDispatch<void, void, GetPeopleAction>
): Promise<GraphUser[]> =>
    executeWithTelemetry(
        'GetPeople',
        async (event: TimedTelemetryEvent): Promise<GraphUser[]> => {
            let searchedPersonas: GraphUser[] = [];
            const encodedPrefix = encodeURIComponent(prefix);
            const response = await services.http
                .get(`${config.serviceEndpoint}/v1/dynamicsextension/getpeople/${encodedPrefix}/${limit}`)
                .catch((): void => event.reject());
            event.resolve();
            if (response) {
                searchedPersonas = await response.body.json();
            }
            dispatch(getPeople(searchedPersonas));
            return searchedPersonas;
        }
    );

export type Actions = GetPeopleAction;

import { AnyAction } from 'redux';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Pivot, PivotItem } from 'office-ui-fabric-react';

import { dismissNotificationBarThunk, getReferDetailsFormThunk } from '../../store/actions/refer/refer';
import { getReferralHistoryThunk, getJobSuggestionsFormThunk, submitReferralFormThunk } from '../../store/actions';
import { ReferAppStoreState } from '../../store/reducers';
import ReferForm, { ReferFormProps } from '../../components/ReferForm/ReferForm';
import ReferHistory, { ReferHistoryProps } from '../../components/ReferHistory/ReferHistory';
import ReferCoachmark from '../../components/Coachmark/ReferCoachmark';
import { ReferDetails, ReferFormModel, JobDetails } from '../../environment/interfaces';

import styles from './ReferPivot.module.css';

interface ReferPivotStateProps {
    referHistoryProps: ReferHistoryProps;
    referFormProps: ReferFormProps;
}

interface ReferPivotDispatchProps {
    onGetReferralHistory(): void;
    getJobSuggestions(jobId: string): Promise<JobDetails | null>;
    getReferDetails(referId: string): Promise<ReferDetails | null>;
    onSubmitReferralForm(referForm: ReferFormModel): Promise<void>;
    onDismissNotificationBar(): void;
}

// eslint-disable-next-line prettier/prettier
interface ReferPivotProps extends ReferPivotStateProps, ReferPivotDispatchProps, Partial<RouteComponentProps> {}

export const ReferPivot: React.FunctionComponent<ReferPivotProps> = (props: ReferPivotProps): JSX.Element => {
    const [selectedKey, setSelectedKey] = React.useState('referForm');

    const changePivotItem = (item?: PivotItem): void => {
        if (item && item.props && item.props.itemKey) {
            setSelectedKey(item.props.itemKey);
        }
    };

    return (
        <>
            <h1 className="visually-hidden">Microsoft Referrals</h1>
            <Pivot className={styles.container} selectedKey={selectedKey} onLinkClick={changePivotItem}>
                <PivotItem headerText="Refer Someone" itemKey="referForm">
                    <ReferForm
                        {...props.referFormProps}
                        location={props.location}
                        onJobSearch={props.getJobSuggestions}
                        onReferAgain={props.getReferDetails}
                        onSubmitForm={props.onSubmitReferralForm}
                        onDismissNotificationBar={props.onDismissNotificationBar}
                    />
                </PivotItem>

                <PivotItem headerText="Previous Referrals" itemKey="referHistory">
                    <ReferHistory
                        {...props.referHistoryProps}
                        setPivotItem={setSelectedKey}
                        location={props.location}
                        history={props.history}
                        onGetHistory={props.onGetReferralHistory}
                    />
                </PivotItem>
            </Pivot>
            <ReferCoachmark />
        </>
    );
};

const mapStateToProps = (state: ReferAppStoreState): ReferPivotStateProps => ({
    referHistoryProps: state.history,
    referFormProps: state.referForm
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, AnyAction>): ReferPivotDispatchProps => ({
    onGetReferralHistory: (): Promise<void> => dispatch(getReferralHistoryThunk()),
    getJobSuggestions: (jobId: string): Promise<JobDetails | null> => dispatch(getJobSuggestionsFormThunk(jobId)),
    getReferDetails: (referId: string): Promise<ReferDetails | null> => dispatch(getReferDetailsFormThunk(referId)),
    onSubmitReferralForm: (referForm: ReferFormModel): Promise<void> => dispatch(submitReferralFormThunk(referForm)),
    onDismissNotificationBar: (): Promise<void> => dispatch(dismissNotificationBarThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferPivot);

import { ocvFloodGateConfig } from '../config';
import { campaignDefinitions } from '../constants/campaigns';
import { SurveyTelemetrySinkProvider } from './telemetrySinkProvider';

const sinkProvider = new SurveyTelemetrySinkProvider();

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        OfficeBrowserFeedback: any;
    }
}

const setEvents = (): void => {
    window.onclick = (): void => {
        if (window.OfficeBrowserFeedback.floodgate) {
            window.OfficeBrowserFeedback.floodgate.start();
        }
    };

    window.onfocus = (): void => {
        if (window.OfficeBrowserFeedback.floodgate) {
            window.OfficeBrowserFeedback.floodgate.start();
        }
    };

    window.onblur = (): void => {
        if (window.OfficeBrowserFeedback.floodgate) {
            window.OfficeBrowserFeedback.floodgate.stop();
        }
    };

    window.onunload = (): void => {
        if (window.OfficeBrowserFeedback.floodgate) {
            window.OfficeBrowserFeedback.floodgate.stop();
        }
    };
};

export const initOCVFloodGate = (): void => {
    if (window && window.OfficeBrowserFeedback) {
        window.OfficeBrowserFeedback = window.OfficeBrowserFeedback || {};
        window.OfficeBrowserFeedback.floodgate = window.OfficeBrowserFeedback.floodgate || {};

        /* init options */
        window.OfficeBrowserFeedback.initOptions = {
            ...ocvFloodGateConfig,
            userEmail: '',
            userEmailConsentDefault: false,
            sessionId: '',
            telemetryGroup: {
                featureArea: 'Global Talent Acquisition'
            },
            bugForm: false, // (optional) false by default
            webGroup: {
                sourcePageURI: window.location.href
            },
            categories: {
                show: false
            },
            telemetrySink: sinkProvider.getTelemetrySink()
        };

        /* set options for floodgate */
        // 7*x seconds, 0: No auto-dismiss (default), 1: 7s, 2: 14s, 3: 21s, 4: 28s.
        // Duration after which floodgate prompt automatically dismissed if not clicked? Default is no auto-dismiss.
        window.OfficeBrowserFeedback.floodgate.initOptions = {
            autoDismiss: 4,
            campaignDefinitions
        };
        window.OfficeBrowserFeedback.floodgate.initialize().then((): void => {
            setEvents();
        });
    }
};

export const triggerSurvey = (survey: string): void => {
    if (window && window.OfficeBrowserFeedback && window.OfficeBrowserFeedback.floodgate) {
        window.OfficeBrowserFeedback.floodgate.start().then((): void => {
            window.OfficeBrowserFeedback.floodgate
                .getEngine()
                .getActivityListener()
                .logActivity(survey);
        });
    }
};

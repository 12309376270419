import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import InterviewFeedbackDashboard, {
    InterviewFeedbackDashboardProps
} from '../../../components/UR/InterviewFeedbackDashboard/InterviewFeedbackDashboard';
import { ReferAppStoreState } from '../../../store/reducers';
import { InterviewFeedbackMetadata } from '../../../environment/interfaces/InterviewFeedbackMetadata';
import { getAAMetadataThunk } from '../../../store/actions';

const mapStateToProps = (state: ReferAppStoreState): InterviewFeedbackDashboardProps => ({
    ...state.metadata
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, AnyAction>): Partial<InterviewFeedbackDashboardProps> => ({
    onGetMetadata: (): Promise<InterviewFeedbackMetadata[]> => dispatch(getAAMetadataThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewFeedbackDashboard);

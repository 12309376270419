import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { initializeIcons } from 'office-ui-fabric-react';
import { EventType, EventMessage, AuthenticationResult, AuthError } from '@azure/msal-browser';
import { msalInstance } from './environment/msalConfig';

import { App } from './containers/App/App';
import rootReducer from './store/reducers';

import './index.css';
import { i18nInit } from './framework/utilities/i18n.config';

const isProdEnvironment = process.env.REACT_APP_ENV_NAME === 'prod';

initializeIcons(void 0, { disableWarnings: isProdEnvironment });
const store = createStore(rootReducer, applyMiddleware(thunk));

msalInstance
    .handleRedirectPromise()
    .then((): void => {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }
    })
    .catch((error): void => {
        if (error instanceof AuthError) {
            if ('localStorage' in window) {
                window.localStorage.clear();
            }
        }
    });

msalInstance.addEventCallback((event: EventMessage): void => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

i18nInit(isProdEnvironment);

ReactDOM.render(
    <Provider store={store}>
        <App pca={msalInstance} />
    </Provider>,
    document.getElementById('root')
);

import React from 'react';

import { DirectionalHint, IButtonProps, Coachmark, TeachingBubbleContent } from 'office-ui-fabric-react';
import { useBoolean } from '@uifabric/react-hooks';

export const ReferCoachmark: React.FunctionComponent = (): JSX.Element => {
    const [isCoachmarkVisible, { setFalse: hideCoachmark }] = useBoolean(localStorage.getItem('ReferAgainAcknowledged') !== 'true');
    const [coachmarkPosition] = React.useState<DirectionalHint>(DirectionalHint.bottomCenter);
    const positioningContainerProps = React.useMemo(
        (): {} => ({
            directionalHint: coachmarkPosition,
            doNotLayer: false
        }),
        [coachmarkPosition]
    );

    const buttonProps: IButtonProps = {
        text: 'Got it',
        onClick: (): void => {
            localStorage.setItem('ReferAgainAcknowledged', 'true');
            hideCoachmark();
        }
    };

    return (
        <>
            {isCoachmarkVisible && (
                <Coachmark
                    target="#Pivot0-Tab1"
                    positioningContainerProps={positioningContainerProps}
                    ariaAlertText="A coachmark has appeared"
                    ariaDescribedBy="coachmark-desc1"
                    ariaLabelledBy="coachmark-label1"
                    ariaDescribedByText="Press enter or alt + C to open the coachmark notification"
                    ariaLabelledByText="Coachmark notification"
                >
                    <TeachingBubbleContent
                        headline="Previous Referral"
                        hasCloseButton
                        closeButtonAriaLabel="Close"
                        primaryButtonProps={buttonProps}
                        onDismiss={hideCoachmark}
                        ariaDescribedBy="example-description1"
                        ariaLabelledBy="example-label1"
                    >
                        View candidates you have already referred, check on their status and refer them again to other open jobs.
                    </TeachingBubbleContent>
                </Coachmark>
            )}
        </>
    );
};

export default ReferCoachmark;

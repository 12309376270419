import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { TimedTelemetryEvent } from '@gta/core';

import * as ActionTypes from './actionTypes';
import { DynamcisInterviewFeedback } from '../../../../environment/interfaces/DynamcisInterviewFeedback';
import { executeWithTelemetry } from '../../../../environment/services/telemetry';
import { services, config } from '../../../../environment';

export interface GetInterviewFeedbackAction extends Action<typeof ActionTypes.GET_INTERVIEW_FEEDBACK> {
    feedbackDetails?: DynamcisInterviewFeedback;
}

export interface SetIsLoadingAction extends Action<typeof ActionTypes.SET_ISLOADING> {
    isLoading?: boolean;
}

const getInterviewFeedback = (feedbackDetails: DynamcisInterviewFeedback): GetInterviewFeedbackAction => ({
    type: ActionTypes.GET_INTERVIEW_FEEDBACK,
    feedbackDetails
});

const setIsLoading = (isLoading: boolean): SetIsLoadingAction => ({
    type: ActionTypes.SET_ISLOADING,
    isLoading
});

export const getInterviewFeedbackThunk = (
    applicationId: string
): ThunkAction<Promise<DynamcisInterviewFeedback>, void, void, GetInterviewFeedbackAction> => async (
    dispatch: ThunkDispatch<void, void, GetInterviewFeedbackAction>
): Promise<DynamcisInterviewFeedback> =>
    executeWithTelemetry(
        'GetInterviewFeedback',
        async (event: TimedTelemetryEvent): Promise<DynamcisInterviewFeedback> => {
            let feedbacks: DynamcisInterviewFeedback = {};
            const response = await services.http
                .get(`${config.serviceEndpoint}/v1/dynamicsextension/${applicationId}/feedbacks`)
                .catch((): void => event.reject());
            event.resolve();
            if (response) {
                feedbacks = await response.body.json();
            }
            dispatch(getInterviewFeedback(feedbacks));
            return feedbacks;
        }
    );

export const setIsLoadingThunk = (): ThunkAction<Promise<boolean>, void, void, SetIsLoadingAction> => async (
    dispatch: ThunkDispatch<void, void, SetIsLoadingAction>
): Promise<boolean> => {
    dispatch(setIsLoading(true));
    return true;
};

export type Actions = GetInterviewFeedbackAction | SetIsLoadingAction;

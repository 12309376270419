enum ReferStatus {
    ReferralSubmitted = 0,
    SyncedToExternalSystem = 1,
    Declined = 2,
    PendingCandidateResponse = 3,
    Submitted = 4,
    Hired = 5,
    Active = 6,
    Inactive = 7,
    Ineligible = 8,
    NotConsented = 9,
    Apply = 10,
    Screen = 11,
    InterviewRequested = 12,
    InterviewScheduling = 13,
    InterviewFeedback = 14,
    Offer = 15,
    OfferExtend = 16,
    OfferAccepted = 17,
    PreOnboard = 18
}

export default ReferStatus;

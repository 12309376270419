export const invalidContentMessage = 'Contains invalid characters. The only non-alphanumeric characters allowed are ,&."-"`()/|:®. ';
export const invalidCountryMessage =
    'We are sorry, but unfortunately we cannot accept referrals for candidates in Russia due to data privacy regulations. We would kindly ask you to encourage the candidate to apply directly on our Careers site.';
export const invalidDomainMessage =
    'We are sorry, but unfortunately we cannot accept referrals for candidates with @microsoft.com. We would kindly ask you to encourage the candidate to apply directly on our Careers site. ';
export const invalidEmailMessage = 'Email address format is not correct. ';
export const invalidPhoneMessage =
    'Contains invalid characters. The format should be an optional + followed by digits from 0-9 and no whitespaces. ';
export const invalidURLMessage =
    "Valid URL should start with http:// or https:// and the only non-alphanumeric characters allowed are |[]-()_.!~*';/?#:@&=+$,%. ";
export const requiredFieldMessage = 'This field is required.';
export const requiredResumeOrLinkedinUrl = "Either candidate's resume or link to their LinkedIn profile is required.";
export const inCorrectJobID = 'Job ID is not correct.';

import * as ActionTypes from '../../../actions/ur/dynFeedbackPrivilege/actionTypes';
import { Actions } from '../../../actions/ur/dynFeedbackPrivilege/dynFeedbackPrivilege';
import { DynamcisInterviewFeedback } from '../../../../environment/interfaces/DynamcisInterviewFeedback';

export interface DynFeedbackPrivilegeStoreState {
    feedbackDetails: DynamcisInterviewFeedback;
    isLoading: boolean;
}

const initialState: DynFeedbackPrivilegeStoreState = {
    feedbackDetails: {},
    isLoading: true
};

const reducer = (state: DynFeedbackPrivilegeStoreState = initialState, action: Actions): DynFeedbackPrivilegeStoreState => {
    switch (action.type) {
        case ActionTypes.GET_FEEBACK_VIEWING_PRIVILEGES:
            return { ...state, feedbackDetails: action.feedbackDetails || initialState.feedbackDetails, isLoading: false };
        case ActionTypes.DELETE_FEEBACK_VIEWING_PRIVILEGE:
            return { ...state, feedbackDetails: { ...action.feedbackDetails } };
        case ActionTypes.ADD_FEEBACK_VIEWING_PRIVILEGE:
            return { ...state, feedbackDetails: { ...action.feedbackDetails } };
        default: {
            return state;
        }
    }
};

export default reducer;

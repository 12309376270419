import {
    FormValidationDescriptor,
    createPatternValidator,
    createRequiredValidator,
    createMaxLengthValidator,
    FormGroup,
    FormControlValidator
} from '@gta/core';

import * as errorCodes from '../../shared/validators/errorCodes';
import * as patterns from '../../framework/form/validation/shared/patterns';
import { locations as locationCountryCodes, profiles as profilesData } from '../../environment/constants';
import { ReferEndorse, Acquaintance, CountryCode } from '../../environment/contracts';
import { patternValidator, maxLengthValidator, requiredValidator } from '../../shared/validators';
import { ExtendedChoiceGroupOption, ExtendedOption, ReferFormModel } from '../../environment/interfaces';

export const endorsementChoices: ExtendedChoiceGroupOption<ReferEndorse>[] = [
    {
        key: 'Yes',
        text: 'Yes',
        payload: ReferEndorse.Yes,
        ariaLabel: 'Do you endorse this person professionally and recommend them as a hire - Yes'
    },
    {
        key: 'DontKnowEnough',
        text: "I don't know enough",
        payload: ReferEndorse.DontKnowEnough,
        ariaLabel: 'Do you endorse this person professionally and recommend them as a hire - DontKnowEnough'
    }
];

const relationsData: { text: string; key: number }[] = [
    { text: "I don't know this person directly", key: Acquaintance.DontKnowDirectly },
    { text: "I know this person, but haven't worked with them", key: Acquaintance.KnowButNotWorkedTogether },
    { text: 'I went to college/university with this person', key: Acquaintance.WentToCollegeWith },
    { text: 'I have worked with this person before', key: Acquaintance.HaveWorkedWith }
];

export const univesityCandidateChoices: ExtendedChoiceGroupOption<boolean>[] = [
    { key: 'Yes', text: 'Yes', payload: true, ariaLabel: 'Is your referral a current university student or recent graduate * - Yes' },
    { key: 'No', text: 'No', payload: false, ariaLabel: 'Is your referral a current university student or recent graduate * - No' }
];

export const locations = locationCountryCodes.map((location): ExtendedOption<string> => ({ ...location, payload: 'location' }));

export const profiles = profilesData.map((profile): ExtendedOption<string> => ({ ...profile, payload: 'profile' }));

export const relations = relationsData.map((relation): ExtendedOption<string> => ({ ...relation, payload: 'acquaintanceLevel' }));

const candidateEmailValidators: FormControlValidator<string, ReferFormModel, string | null>[] = [
    patternValidator(createPatternValidator(patterns.emailPattern), errorCodes.invalidEmailMessage),
    patternValidator(createPatternValidator(patterns.domainPattern), errorCodes.invalidDomainMessage)
];

const nameValidator = patternValidator(createPatternValidator(patterns.namePattern), errorCodes.invalidContentMessage);
const maxFieldLengthValidator = maxLengthValidator(createMaxLengthValidator(100));
const requiredFieldValidator = (trim: boolean): FormControlValidator<string, ReferFormModel, string | null> =>
    requiredValidator(createRequiredValidator({ trim }));

const countryValidator = (value: number): string | null => {
    if (!value && value !== 0) {
        return errorCodes.requiredFieldMessage;
    } else if (value === CountryCode.RU) {
        return errorCodes.invalidCountryMessage;
    }

    return null;
};

const emailFormatValidator = (value: string, form: FormGroup<ReferFormModel>): string | null => {
    const error = candidateEmailValidators.find((a): boolean => a(value, form) !== null);
    return error ? error(value, form) : null;
};

const resumeValidator = (referKey: keyof ReferFormModel): FormControlValidator<string, ReferFormModel, string | null> => {
    return (value: string | File, form: FormGroup<ReferFormModel>): string | null => {
        const linkedInURL = referKey === 'linkedInURL' ? value : form.linkedInURL.value;
        const resumeFile = referKey === 'resumeFile' ? value : form.resumeFile.value;
        const isError = !(resumeFile || linkedInURL);
        return isError ? errorCodes.requiredResumeOrLinkedinUrl : null;
    };
};

const universityStudentGroupValidator = (referKey: keyof ReferFormModel): FormControlValidator<boolean, ReferFormModel, string | null> => {
    return (value: boolean | number, form: FormGroup<ReferFormModel>): string | null => {
        const isUniversityStudent = referKey === 'isUniversityStudent' ? value : form.isUniversityStudent.value;
        const isError = isUniversityStudent === null;
        return isError ? errorCodes.requiredFieldMessage : null;
    };
};

export const referFormValidtionDescriptor: FormValidationDescriptor<ReferFormModel> = {
    firstName: [
        ``,
        {
            required: requiredFieldValidator(true),
            content: nameValidator,
            maxLength: maxFieldLengthValidator
        }
    ],
    lastName: [
        ``,
        {
            required: requiredFieldValidator(true),
            content: nameValidator,
            maxLength: maxFieldLengthValidator
        }
    ],
    candidateEmail: [
        ``,
        {
            required: requiredFieldValidator(true),
            emailFormat: emailFormatValidator
        }
    ],
    candidatePhone: [
        ``,
        {
            maxLength: maxLengthValidator(createMaxLengthValidator(15)),
            phonePattern: patternValidator(createPatternValidator(patterns.phonePattern), errorCodes.invalidPhoneMessage)
        }
    ],
    location: [
        null,
        {
            country: countryValidator
        }
    ],
    jobs: [[], {}],
    profile: [``, { required: requiredFieldValidator(false) }],
    resumeUri: [``, {}],
    resumeFile: [null, { required: resumeValidator('resumeFile') }],
    linkedInURL: [
        ``,
        {
            required: resumeValidator('linkedInURL'),
            URLPattern: patternValidator(createPatternValidator(patterns.linkedInPattern), errorCodes.invalidURLMessage)
        }
    ],
    acquaintanceLevel: [Acquaintance.NotSelected, { required: requiredFieldValidator(false) }],
    isEndorsed: [ReferEndorse.NotSelected, { required: requiredFieldValidator(false) }],
    isUniversityStudent: [null, { required: universityStudentGroupValidator('isUniversityStudent') }],
    additionalInformation: [``, { maxLength: maxLengthValidator(createMaxLengthValidator(2000)) }],
    campaignCode: [``, { maxLength: maxLengthValidator(createMaxLengthValidator(100)) }]
};

import { FormControlValidator, FormGroup } from '@gta/core';

import { ReferFormModel } from '../../environment/interfaces';

export const patternValidator = (
    controlValidator: FormControlValidator<string, ReferFormModel, boolean>,
    errorMessage: string,
    errorOnMatch: boolean = false
): FormControlValidator<string, ReferFormModel, string | null> => (value: string, form: FormGroup<ReferFormModel>): string | null => {
    let error = controlValidator(value, form);
    if (errorOnMatch) {
        error = !error;
    }
    return error ? errorMessage : null;
};

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { TimedTelemetryEvent } from '@gta/core';

import * as ActionTypes from './actionTypes';
import { config, services } from '../../../environment';
import { executeWithTelemetry } from '../../../environment/services/telemetry';

export type PostCandidateConsentAction = Action<typeof ActionTypes.POST_CANDIDATE_CONSENT>;

export type CandidateConsentRequest = Action<typeof ActionTypes.CANDIDATE_CONSENT_REQUEST>;

type CandidateConsentAction = CandidateConsentRequest | PostCandidateConsentAction;

const postCandidateConsent = (): PostCandidateConsentAction => ({
    type: ActionTypes.POST_CANDIDATE_CONSENT
});

const candidateConsentRequest = (): CandidateConsentRequest => ({
    type: ActionTypes.CANDIDATE_CONSENT_REQUEST
});

export const postCandidateConsentThunk = (
    consent: boolean,
    referId: string
): ThunkAction<Promise<void>, void, void, CandidateConsentAction> => async (
    dispatch: ThunkDispatch<void, void, CandidateConsentAction>
): Promise<void> =>
    executeWithTelemetry(
        'PostCandidateConsent',
        async (event: TimedTelemetryEvent): Promise<void> => {
            dispatch(candidateConsentRequest());
            await services.http
                .post(`${config.serviceEndpoint}/v2/candidate/consent/${referId}/${consent}`, null)
                .catch((): void => event.reject());
            event.resolve();
            dispatch(postCandidateConsent());
        }
    );

export type Actions = CandidateConsentRequest | PostCandidateConsentAction;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    ActionButton,
    Persona,
    PersonaSize,
    PersonaPresence,
    IPersonaSharedProps,
    NormalPeoplePicker,
    IPersonaProps,
    Spinner,
    SpinnerSize,
    Text
} from 'office-ui-fabric-react';

import { es } from '@gta/core';

import { Condition } from '../../../framework/components/Condition';
import { DynamcisInterviewFeedback } from '../../../environment/interfaces/DynamcisInterviewFeedback';
import { GraphUser } from '../../../environment/interfaces/GraphUser';
import { HiringTeamMember } from '../../../environment/interfaces/HiringTeamMember';
import { JobApplicationActivityDetails } from '../../../environment/interfaces/JobApplicationActivityDetails';

import styles from './FeedbackViewingPrivileges.module.css';

const personaProps: IPersonaSharedProps = {};

export interface FeedbackViewingPrivilegesProps extends Partial<RouteComponentProps> {
    feedbackDetails?: DynamcisInterviewFeedback;
    isLoading?: boolean;
    onGetFeedbackViewingPrivileges?(jobId: string, applicationId: string): Promise<DynamcisInterviewFeedback>;
    onDeleteMember?(applicationId: string, userId: string, participants: DynamcisInterviewFeedback): Promise<void>;
    onAddMember?(applicationId: string, userId: string, participants: DynamcisInterviewFeedback): Promise<void>;
    onGetPeople?(prefix: string): Promise<GraphUser[]>;
}

export const FeedbackViewingPrivileges: React.FunctionComponent<FeedbackViewingPrivilegesProps> = (
    props: FeedbackViewingPrivilegesProps
): JSX.Element => {
    const [queryStringExists, setQueryStringExists] = useState(true);

    const getJobApplicationActivityDetails = (): JobApplicationActivityDetails | undefined => {
        if (props.location && props.location.search) {
            const queryList = es.parseQueryString(props.location.search);
            const activityDetails: JobApplicationActivityDetails = {
                jobId: queryList.jobId,
                applicationId: queryList.applicationId,
                activityId: queryList.activityId
            };
            return activityDetails;
        }
    };

    const getApplicationIdFromQueryString = (): string => {
        var parsed = getJobApplicationActivityDetails();
        if (parsed && parsed.applicationId) {
            return parsed.applicationId.toString();
        }
        return '';
    };

    useEffect((): void => {
        if (props.onGetFeedbackViewingPrivileges) {
            const jobAppDetails = getJobApplicationActivityDetails();
            if (jobAppDetails && jobAppDetails.jobId && jobAppDetails.applicationId) {
                props.onGetFeedbackViewingPrivileges(jobAppDetails.jobId, jobAppDetails.applicationId);
                setQueryStringExists(true);
            } else {
                setQueryStringExists(false);
            }
        }
        document.title = 'Interview Feedback';
    }, []);

    const onDeleteMember = async (userId: string | undefined): Promise<void> => {
        if (props.feedbackDetails && props.onDeleteMember && userId) {
            const applicationId = getApplicationIdFromQueryString();
            props.onDeleteMember(applicationId, userId, props.feedbackDetails);
        }
    };

    const onAddMember = async (userId: string): Promise<boolean> => {
        if (props.feedbackDetails && props.onAddMember && userId) {
            const applicationId = getApplicationIdFromQueryString();
            props.onAddMember(applicationId, userId, props.feedbackDetails);
        }
        return true;
    };

    const onFilterChanged = async (filterText: string): Promise<IPersonaProps[]> => {
        if (filterText) {
            let searchedPersonas: IPersonaProps[] = [];
            if (props.onGetPeople) {
                let searchedPeople = await props.onGetPeople(filterText);
                if (searchedPeople) {
                    searchedPeople.map((user: GraphUser): void => {
                        searchedPersonas.push({ text: user.displayName, id: user.id, secondaryText: user.userPrincipalName });
                    });
                }
            }
            return searchedPersonas;
        } else {
            return [];
        }
    };

    const onChangeSelectionItem = (items?: IPersonaProps[]): void => {
        if (items && items.length && items[0].id) {
            onAddMember(items[0].id);
        }
    };

    const ListPicker = (): JSX.Element => {
        return (
            <div style={{ width: '250px' }} className={styles.listPicker}>
                <Text as="h2" className={styles.addMemberText} variant="mediumPlus">
                    Add team members
                </Text>
                <NormalPeoplePicker
                    onResolveSuggestions={onFilterChanged}
                    selectedItems={[]}
                    onChange={onChangeSelectionItem}
                    inputProps={{
                        'aria-label': 'People Picker',
                        placeholder: 'Enter a name or email address'
                    }}
                    resolveDelay={500}
                />
            </div>
        );
    };

    const Participants = (props: FeedbackViewingPrivilegesProps): JSX.Element => {
        return (
            <>
                {
                    <Condition
                        if={
                            !props.isLoading &&
                            (!props.feedbackDetails ||
                                !props.feedbackDetails.interviewers ||
                                props.feedbackDetails.interviewers.length === 0)
                        }
                        then={<p>No participants were found.</p>}
                        else={
                            <>
                                <table>
                                    <thead>
                                        <tr className={styles.headerRow}>
                                            <th className={styles.nameColumnHeader}>NAME</th>
                                            <th className={styles.actionsColumnHeader}>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.feedbackDetails &&
                                            props.feedbackDetails.interviewers &&
                                            props.feedbackDetails.interviewers.map(
                                                (item: HiringTeamMember): JSX.Element => (
                                                    (personaProps.text = item.fullName),
                                                    (
                                                        <React.Fragment key={item.objectId}>
                                                            <tr className={styles.nonHeaderRow}>
                                                                <td className={styles.nameColumn}>
                                                                    <span>
                                                                        <Persona
                                                                            {...personaProps}
                                                                            size={PersonaSize.size24}
                                                                            presence={PersonaPresence.none}
                                                                            hidePersonaDetails={false}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td className={styles.deleteIconColumn}>
                                                                    <ActionButton
                                                                        name="delete"
                                                                        className={styles.deleteIcon}
                                                                        iconProps={{ iconName: 'Delete', className: styles.deleteIcon }}
                                                                        onClick={(): Promise<void> => onDeleteMember(item.objectId)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </>
                        }
                    />
                }
            </>
        );
    };

    return (
        <div className={styles.layout}>
            {
                <Condition
                    if={!queryStringExists}
                    then={<p>Job application details were missing.</p>}
                    else={
                        !props.isLoading && props.feedbackDetails && !props.feedbackDetails.isAuthorized ? (
                            <p>You’re not authorized to access this. If you need help, contact your administrator.</p>
                        ) : props.isLoading ? (
                            <Spinner className={styles.loginSpinner} size={SpinnerSize.large} label="Loading" />
                        ) : (
                            <>
                                <ListPicker />
                                <Participants {...props} />
                            </>
                        )
                    }
                />
            }
        </div>
    );
};

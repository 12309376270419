/* eslint-disable prettier/prettier */
import { AccountInfo, Configuration, PublicClientApplication, SilentRequest } from '@azure/msal-browser';

const msalConfig: Configuration = {
    ...(window && window.location.pathname.includes('/referrals')
        ? {
            auth: {
                clientId: process.env.REACT_APP_B2C_MSAL_CLIENT_ID || 'c6e9a05d-17e9-4350-8697-de2c96627428',
                authority: process.env.REACT_APP_B2C_MSAL_AUTHORITY,
                redirectUri: `${window.location.origin}/referrals`,
                postLogoutRedirectUri: window.location.origin,
                knownAuthorities: [`${process.env.REACT_APP_B2C_MSAL_TENANT}`]
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            }
        } : {
            auth: {
                clientId: process.env.REACT_APP_AAD_CLIENT_ID || 'bd613935-9d03-4e7f-a037-0cdd256a0155',
                authority: 'https://login.microsoftonline.com/microsoft.com',
                redirectUri: `${window.location.origin}/login`,
                postLogoutRedirectUri: `${window.location.origin}/login`
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            }
        })
};

export const tokenSilentRequest = (account: AccountInfo | undefined): SilentRequest => ({
    ...(window && window.location.pathname.includes('/referrals') ? {
        scopes: ['openid', `${process.env.REACT_APP_B2C_API_SCOPE}`],
        account,
    } : {
        scopes: [`${process.env.REACT_APP_AAD_CLIENT_ID || 'bd613935-9d03-4e7f-a037-0cdd256a0155'}/.default`],
        account
    })
});

export const msalInstance = new PublicClientApplication(msalConfig);

import { Actions } from '../../actions/history/history';
import * as ActionTypes from '../../actions/history/actionTypes';
import { ReferHistoryDetail } from '../../../environment/interfaces/ReferHistoryDetail';

export interface ReferHistoryStoreState {
    historyDetails: ReferHistoryDetail[];
    isLoading: boolean;
}

const initialState: ReferHistoryStoreState = {
    historyDetails: [],
    isLoading: true
};

const reducer = (state: ReferHistoryStoreState = initialState, action: Actions): ReferHistoryStoreState => {
    switch (action.type) {
        case ActionTypes.GET_REFERRAL_HISTORY:
            return { ...state, historyDetails: action.historyDetails || [], isLoading: action.isLoading };
        default:
            return state;
    }
};

export default reducer;

import AuthenticationContext from 'adal-angular';
import { Snippet } from '@microsoft/applicationinsights-web';

import { primitives } from '@gta/core';

import { Person } from './interfaces/person';
import { urBaseRoute } from './constants/ur';

type EnvironmentName = 'dev' | 'int' | 'prod' | 'local';

interface ApplicationConfig {
    adalConfig: AuthenticationContext.Options;
    application: string;
    build: { id: string };
    environment: EnvironmentName;
    serviceEndpoint: string;
    sessionId: string;
    telemetry: {
        logToConsole: boolean;
        applicationInsights: Snippet;
    };
    userProfile: Person;
    urls: Record<string, string>;
    clientEndpoint: string;
}

export const config: ApplicationConfig = {
    adalConfig: {
        cacheLocation: 'localStorage',
        clientId: process.env.REACT_APP_AAD_CLIENT_ID || 'bd613935-9d03-4e7f-a037-0cdd256a0155',
        localLoginUrl: `${window.location.origin}`,
        tenant: 'microsoft.com',
        redirectUri: `${window.location.origin}/login`
    },
    // TODO update application name based on route changes
    application: window.location.pathname.startsWith(urBaseRoute) ? 'UR' : 'Refer',
    build: {
        id: process.env.REACT_APP_BUILD_ID || 'DEVELOPMENT'
    },
    environment: (process.env.REACT_APP_ENV_NAME as EnvironmentName) || 'local',
    serviceEndpoint: process.env.REACT_APP_SERVICE_ENDPOINT || 'https://hrgtareferservicedev.azurewebsites.net',
    sessionId: primitives.generateGuid(),
    telemetry: {
        logToConsole: !process.env.REACT_APP_ENV_NAME,
        applicationInsights: { config: { instrumentationKey: process.env.REACT_APP_APPINSIGHTS_IKEY } }
    },
    userProfile: {},
    urls: {
        attract: process.env.REACT_APP_ATTRACT_BASE_URL || 'https://attract.talent.dev.dynamics.com/'
    },
    clientEndpoint: process.env.REACT_APP_CLIENT_BASE_URL || 'http://localhost:3000'
};

export const ocvFloodGateConfig = {
    appId: 2378,
    environment: process.env.REACT_APP_ENV_NAME === 'prod' ? 0 : 1,
    primaryColour: '#0B53CE',
    secondaryColour: '#0B53CE',
    intlUrl: `${process.env.REACT_APP_CLIENT_BASE_URL || 'http://localhost:3000'}/assets/ocv/intl/`,
    stylesUrl: `${process.env.REACT_APP_CLIENT_BASE_URL || 'http://localhost:3000'}/assets/ocv/officebrowserfeedback.min.css`,
    locale: 'en'
};

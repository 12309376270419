import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    IColumn,
    Icon,
    Link,
    Persona,
    PersonaSize,
    PersonaPresence,
    IPersonaSharedProps,
    Spinner,
    SpinnerSize,
    Text,
    ActionButton
} from 'office-ui-fabric-react';

import { es } from '@gta/core';

import { config } from '../../../environment';
import { DynamcisInterviewFeedback } from '../../../environment/interfaces/DynamcisInterviewFeedback';
import { HiringTeamMember } from '../../../environment/interfaces/HiringTeamMember';
import { JobApplicationAssessmentStatus } from '../../../environment/contracts/JobApplicationAssessmentStatus';
import { JobApplicationActivityDetails } from '../../../environment/interfaces/JobApplicationActivityDetails';
import { InterviewFeedbackHeader } from '../InterviewFeedbackHeader/InterviewFeedbackHeader';
import { InterviewFeedbackDetail } from '../../../environment/interfaces/InterviewFeedbackDetail';

import styles from './InterviewFeedback.module.css';

const personaProps: IPersonaSharedProps = {};

export interface InterviewFeedbackState {
    columns: IColumn[];
    items: InterviewFeedbackDetail[];
}

export interface InterviewFeedbackProps extends Partial<RouteComponentProps> {
    isLoading?: boolean;
    feedbackDetails: DynamcisInterviewFeedback;
    onGetFeedback?(applicationId: string): Promise<DynamcisInterviewFeedback>;
    setIsLoading?(): Promise<boolean>;
    children?: React.FunctionComponent;
}

export const InterviewFeedback: React.FunctionComponent<InterviewFeedbackProps> = (props: InterviewFeedbackProps): JSX.Element => {
    const [showComments, setShowComments] = useState(false);

    const getJobApplicationActivityDetails = (): JobApplicationActivityDetails | undefined => {
        if (props.location && props.location.search) {
            const queryList = es.parseQueryString(props.location.search);
            const activityDetails: JobApplicationActivityDetails = {
                jobId: queryList.jobId,
                applicationId: queryList.applicationId,
                activityId: queryList.activityId
            };
            return activityDetails;
        }
    };

    useEffect((): void => {
        if (props.setIsLoading) {
            props.setIsLoading();
        }

        if (props.onGetFeedback) {
            const jobAppDetails = getJobApplicationActivityDetails();
            if (jobAppDetails && jobAppDetails.jobId && jobAppDetails.applicationId) {
                props.onGetFeedback(jobAppDetails.applicationId);
            }
        }
        document.title = 'Interview Feedback';
    }, []);

    const getFeedbackProvider = (feedbackParticipant: HiringTeamMember): string | undefined => {
        if (feedbackParticipant && feedbackParticipant.feedbacks && feedbackParticipant.feedbacks.length > 0) {
            let action = '';
            const feedback = feedbackParticipant.feedbacks[feedbackParticipant.feedbacks.length - 1];

            if (feedback && feedback.feedbackProvider && feedback.feedbackProvider.fullName != null) {
                if (feedback.Status === JobApplicationAssessmentStatus.Submitted) {
                    action = 'Submitted by';
                } else if (feedback.Status === JobApplicationAssessmentStatus.InProgress) {
                    action = 'Saved by';
                }
                action = `${action} ${feedback.feedbackProvider.fullName}`;
            }
            return action;
        }
    };

    const getDecision = (feedbackParticipant: HiringTeamMember): string => {
        if (feedbackParticipant && feedbackParticipant.feedbacks && feedbackParticipant.feedbacks.length > 0) {
            const feedback = feedbackParticipant.feedbacks[feedbackParticipant.feedbacks.length - 1];
            if (feedback) {
                const isRecommendedToContinue: boolean = feedback.isRecommendedToContinue;
                return isRecommendedToContinue ? 'Recommended' : 'Not Recommended';
            }
        }
        return '';
    };

    const getStatus = (feedbackParticipant: HiringTeamMember): string => {
        let Status: string;
        Status = 'Not Started';
        if (feedbackParticipant && feedbackParticipant.feedbacks && feedbackParticipant.feedbacks.length > 0) {
            const feedback = feedbackParticipant.feedbacks[feedbackParticipant.feedbacks.length - 1];
            if (feedback) {
                Status = feedback.Status == JobApplicationAssessmentStatus.Submitted ? 'Submitted' : 'Draft';
            }
        }
        return Status;
    };

    const getDynProvideFeedbackLink = (feedbackParticipantId?: string): string => {
        var parsed = getJobApplicationActivityDetails();
        if (parsed && parsed.jobId && parsed.applicationId && feedbackParticipantId) {
            return `${config.urls.attract}jobs/${parsed.jobId}/applications/${parsed.applicationId}/${props.feedbackDetails.interviewStageOrder}/${props.feedbackDetails.interviewFeedbackActivityOrder}/${props.feedbackDetails.interviewFeedbackActivityId}?participant=${feedbackParticipantId}`;
        } else {
            return '';
        }
    };

    const getFeedbackComment = (feedbackParticipant: HiringTeamMember): string => {
        let Comment: string;
        Comment = '';
        if (feedbackParticipant && feedbackParticipant.feedbacks && feedbackParticipant.feedbacks.length > 0) {
            const feedback = feedbackParticipant.feedbacks[feedbackParticipant.feedbacks.length - 1];

            if (feedback) {
                const innerHtmlComment = feedback.overallComment;
                if (innerHtmlComment) {
                    const domNode: Document = new DOMParser().parseFromString(innerHtmlComment, 'text/html');
                    Comment = domNode.documentElement.innerText;
                }
            }
        }
        return Comment;
    };

    const showProvideFeedbackLink = (feedbackParticipant: HiringTeamMember): boolean => {
        if (feedbackParticipant && config.userProfile.objectId === feedbackParticipant.objectId) {
            if (feedbackParticipant.feedbacks && feedbackParticipant.feedbacks.length > 0) {
                const feedback = feedbackParticipant.feedbacks[feedbackParticipant.feedbacks.length - 1];
                return feedback.Status == JobApplicationAssessmentStatus.Submitted;
            }
            return false;
        }
        return true;
    };

    const showFeebackComments = (feedbackParticipant: HiringTeamMember): void => {
        feedbackParticipant.collapsed = !feedbackParticipant.collapsed;
        setShowComments(!showComments);
    };

    return (
        <>
            {!props.isLoading && !props.feedbackDetails.isAuthorized ? (
                <p>You’re not authorized to access this. If you need help, contact your administrator.</p>
            ) : !props.isLoading && (!props.feedbackDetails.interviewers || props.feedbackDetails.interviewers.length === 0) ? (
                <p>No feedbacks were found.</p>
            ) : props.isLoading || props.feedbackDetails == undefined || props.feedbackDetails.interviewers == undefined ? (
                <Spinner className={styles.loginSpinner} size={SpinnerSize.large} label="Loading" />
            ) : (
                <div className={styles.layout}>
                    <InterviewFeedbackHeader {...props} />
                    <div className={styles.header}>
                        <Text as="h3" className={styles.title} variant={'xLarge'}>
                            Feedback
                        </Text>
                        <table>
                            <thead>
                                <tr className={styles.headerRow}>
                                    <th className={styles.columnHeader}>NAME</th>
                                    <th className={styles.columnHeader}>DECISION</th>
                                    <th className={styles.columnHeader}>STATUS</th>
                                    <th className={styles.columnHeader}>ACTIONS</th>
                                    <th className={styles.feedbackIconHeader} />
                                </tr>
                            </thead>
                            <tbody>
                                {props.feedbackDetails.interviewers.map(
                                    (feedbackParticipant: HiringTeamMember): JSX.Element => (
                                        (personaProps.text = feedbackParticipant.fullName),
                                        (personaProps.secondaryText = getFeedbackProvider(feedbackParticipant)),
                                        (
                                            <React.Fragment key={feedbackParticipant.objectId}>
                                                <tr className={styles.nonHeaderRow}>
                                                    <td>
                                                        <span>
                                                            <Persona
                                                                {...personaProps}
                                                                size={PersonaSize.size32}
                                                                presence={PersonaPresence.none}
                                                                hidePersonaDetails={false}
                                                                showSecondaryText={personaProps.secondaryText ? true : false}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td>{getDecision(feedbackParticipant)}</td>
                                                    <td>{getStatus(feedbackParticipant)}</td>
                                                    <td>
                                                        <Link
                                                            name="providefeedbacklink"
                                                            hidden={showProvideFeedbackLink(feedbackParticipant)}
                                                            href={getDynProvideFeedbackLink(feedbackParticipant.objectId)}
                                                            className={styles.provideFeedbackLink}
                                                        >
                                                            <Icon iconName="edit" /> Provide feedback
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <span hidden={!feedbackParticipant.feedbacks}>
                                                            <ActionButton
                                                                name="viewFeedback"
                                                                iconProps={{
                                                                    iconName: 'Chevrondown',
                                                                    className: !feedbackParticipant.collapsed
                                                                        ? styles.collapsed
                                                                        : styles.expanded
                                                                }}
                                                                onClick={(): void => showFeebackComments(feedbackParticipant)}
                                                                ariaLabel="toggle the collapsible feedback content"
                                                                title="Toggle the collapsible feedback content"
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className={styles.commentsRow} hidden={!feedbackParticipant.collapsed}>
                                                    <td colSpan={5}>
                                                        <span className={styles.feedbackComments}>
                                                            {getFeedbackComment(feedbackParticipant)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import CandidateConsent, { CandidateConsentProps } from '../../components/CandidateConsentForm/CandidateConsent';
import { ReferAppStoreState } from '../../store/reducers';
import { postCandidateConsentThunk } from '../../store/actions';

const mapStateToProps = (state: ReferAppStoreState): CandidateConsentProps => ({
    isLoading: state.candidateConsent.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, AnyAction>): Partial<CandidateConsentProps> => ({
    onSubmitConsent: (status: boolean, referId: string): Promise<void> => dispatch(postCandidateConsentThunk(status, referId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateConsent);

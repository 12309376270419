import { Actions } from '../../actions/consent/consent';
import * as ActionTypes from '../../actions/consent/actionTypes';

export interface CandidateConsentStoreState {
    isLoading: boolean;
}

const initialState: CandidateConsentStoreState = {
    isLoading: false
};

const reducer = (state: CandidateConsentStoreState = initialState, action: Actions): CandidateConsentStoreState => {
    switch (action.type) {
        case ActionTypes.CANDIDATE_CONSENT_REQUEST:
            return { ...state, isLoading: true };
        case ActionTypes.POST_CANDIDATE_CONSENT:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

export default reducer;

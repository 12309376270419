import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { TimedTelemetryEvent } from '@gta/core';

import * as ActionTypes from './actionTypes';
import { executeWithTelemetry } from '../../../../environment/services/telemetry';
import { InterviewFeedbackMetadata } from '../../../../environment/interfaces/InterviewFeedbackMetadata';
import { config, services } from '../../../../environment';

export interface GetFeedbackDashboardAction extends Action<typeof ActionTypes.GET_METADATA> {
    metadata?: InterviewFeedbackMetadata[];
}

export interface SetIsLoadingAction extends Action<typeof ActionTypes.SET_ISLOADING> {
    isLoading?: boolean;
}

const getMetadata = (metadata: InterviewFeedbackMetadata[]): GetFeedbackDashboardAction => ({
    type: ActionTypes.GET_METADATA,
    metadata
});

const setIsLoading = (isLoading: boolean): SetIsLoadingAction => ({
    type: ActionTypes.SET_ISLOADING,
    isLoading
});

export const getMetadataThunk = (): ThunkAction<Promise<InterviewFeedbackMetadata[]>, void, void, GetFeedbackDashboardAction> => async (
    dispatch: ThunkDispatch<void, void, GetFeedbackDashboardAction>
): Promise<InterviewFeedbackMetadata[]> =>
    executeWithTelemetry(
        'GetMetadata',
        async (event: TimedTelemetryEvent): Promise<InterviewFeedbackMetadata[]> => {
            let metadata = [];
            const response = await services.http
                .get(`${config.serviceEndpoint}/v1/dynamicsextension/metadata`)
                .catch((): void => event.reject());
            event.resolve();
            if (response) {
                metadata = await response.body.json();
            }
            dispatch(getMetadata(metadata));
            return metadata;
        }
    );

export const setIsLoadingThunk = (): ThunkAction<Promise<boolean>, void, void, SetIsLoadingAction> => async (
    dispatch: ThunkDispatch<void, void, SetIsLoadingAction>
): Promise<boolean> => {
    dispatch(setIsLoading(true));
    return true;
};

export type Actions = GetFeedbackDashboardAction | SetIsLoadingAction;
